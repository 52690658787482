import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pid-tuning-command',
  templateUrl: './pid-tuning-command.component.html',
  styleUrls: ['./pid-tuning-command.component.css'],
})
export class PidTuningCommandComponent {
  command: string = '';
  showDetails: boolean = false;
  errorMessage: string = '';
  selectedfloor: string = '';
  loopname: string = '';
  isloading: boolean = false;

  selectedBlock: string = 'A'; // Default block
  blocks: string[] = [
    'A',
    'B1',
    'B2W',
    'B2E',
    'D1',
    'D2',
    'D3',
    'D4',
    'D5',
    'E',
    'F',
  ];
  floors: string[] = ['L0', 'L1', 'L2', 'L3', 'L4'];
  loopnames: string[] = ['RAT', 'DP'];

  selectedAHU: string = '';
  //   ahus: string[][] = [['UB','L0','Mezz','L2','L3E','L3W'],
  //   ['GS','L0','L1','L2','L3','L4'],
  //   ['WMR','WKK','WGS','WFC','WL1','WL2','WL3','WL4'],
  //   ['EGS','EFC','ETFA','EL1','EL2','EL3','EL4','Sodexo Canteen AHU'],
  //   ['L0','L1','L2','L3','L4'],
  //   ['L0','L1','L2','L3','L4'],
  //   ['L0','L1','L2','L4'],
  //   ['GS','L0','L1','L2','L3','L4'],
  //   ['GS'],
  //   ['DayE','DayW','Rec','Game','Lounge','L2Lobby','L3Lobby','Squash','Audi 1','Audi 2'],
  //   ['A&B Wing GF Corridor AHU','C Wing GF Corridor-Admin AHU','B Wing GF TR Hall AHU','A&B Wing 1st Corridor','B&C Wing 1st Corridor','B Wing 1F TR Hall AHU','C Wing 1F TR Hall AHU','A Wing 1F Reception AHU','A Wing 1F Reception & Back Office', 'TFA-Second Floor','TFA-Third Floor','TFA-Fourth Floor']
  // ];
  ahus: string[][] = [
    ['UB', 'Mezz', 'L2'],
    ['GS', 'L0', 'L1', 'L2', 'L3', 'L4'],
    ['MR', 'KK', 'FC', 'L1', 'L2', 'L3', 'L4'],
    ['FC', 'L1', 'L2', 'L3', 'L4'],
    ['L0', 'L1', 'L2', 'L3', 'L4'],
    ['L0', 'L1', 'L2', 'L3', 'L4'],
    ['L0', 'L1', 'L2', 'L4'],
    ['GS', 'L0', 'L1', 'L2', 'L3', 'L4'],
    ['GS'],
    ['DayE', 'DayW', 'Game', 'Lounge', 'Audi1', 'Audi2'],
    [
      'A&B Wing GF Corridor AHU',
      'C Wing GF Corridor-Admin AHU',
      'B Wing GF TR Hall AHU',
      'A&B Wing 1st Corridor',
      'B&C Wing 1st Corridor',
      'B Wing 1F TR Hall AHU',
      'C Wing 1F TR Hall AHU',
      'A Wing 1F Reception AHU',
      'A Wing 1F Reception & Back Office',
      'TFA-Second Floor',
      'TFA-Third Floor',
      'TFA-Fourth Floor',
    ],
  ];
  set_point: number = 0;
  min_limit: number = 0;
  max_limit: number = 0;
  responseMessage: string = '';
  isLoading: boolean = false;
  blockahumap: Map<string, number> = new Map<string, number>();
  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    for (let i = 0; i < this.blocks.length; i++) {
      this.blockahumap.set(this.blocks[i], i);
    }

    //console.log(this.blockahumap);
    //console.log(this.blockahumap.get("A"),"helllll");
    //console.log(this.selectedBlock);
  }
  showCommand() {
    if (this.command === 'bertlabs') {
      this.showDetails = true;
      this.errorMessage = '';
    } else {
      this.errorMessage = 'Wrong command';
      this.showDetails = false;
    }
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  sendCommand() {
    this.isLoading = true;
    this.isloading = true;

    const data = {
      client: 'hul',
      site: 'ulhi',
      action: 2,
      id1: this.selectedBlock,
      id2: this.selectedAHU,
      body: {
        loop: this.loopname.toLowerCase(),
        id1: this.selectedBlock,
        id2: this.selectedAHU,
        set_point: this.set_point,
        min_limit: this.min_limit,
        max_limit: this.max_limit,
      },
    };

    this.http
      .post(`${environment.apiBaseUrl}/auth/sendCommand`, data)
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isloading = false;
          //console.log(data);
          this.responseMessage = 'Command sent successfully!!';
          if (response.status == 'failed') {
            this.responseMessage =
              'Command sent successfully but there is error in input please try again!!';
            this.openSnackBar(response.msg);
          }
          //console.log('post response',response);
        },
        (error) => {
          this.isLoading = false;
          this.isloading = false;

          this.responseMessage = 'Failed to send command.please try again!';
          this.openSnackBar('failed to send command please try again');

          //console.log(data);
          //console.log('post response',error);
        }
      );
  }
}
