import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-chillerplant-command',
  templateUrl: './chillerplant-command.component.html',
  styleUrls: ['./chillerplant-command.component.css'],
})
export class ChillerplantCommandComponent {
  command: string = '';
  showDetails: boolean = false;
  selectedComponent: string = 'Chillers';
  components: any[] = [];
  Chiller1_status: any = '';
  Chiller1_evap_valve: any = '';
  Chiller_cond_valve: any = '';
  isloading: boolean = false;
  isLoading: boolean = false;
  Chiller2_status: any = '';
  Chiller2_evap_valve: any = '1';
  Chiller2_cond_valve: any = '';
  responseMessage: string = '';
  errorMessage: string = '';
  Chiller3_status: any = '';
  Chiller3_evap_valve: any = '';
  Chiller3_cond_valve: any = '';

  Tower1_val: any = '';

  Tower1_fan1_on_off: any = '';
  Tower1_fan1_speed: any = '';
  Tower1_fan2_on_off: any = '';
  Tower1_fan2_speed: any = '';

  Tower3_val: any = '';

  Tower3_fan1_on_off: any = '';
  Tower3_fan1_speed: any = '';
  Tower3_fan2_on_off: any = '';
  Tower3_fan2_speed: any = '';

  Tower4_val: any = '';

  Tower4_fan1_on_off: any = '';
  Tower4_fan1_speed: any = '';
  Tower4_fan2_on_off: any = '';
  Tower4_fan2_speed: any = '';

  Pump1_Zone_A_on_off: any = '';
  Pump1_Zone_A_speed: any = '';

  Pump2_Zone_A_on_off: any = '';
  Pump2_Zone_A_speed: any = '';

  Pump1_Zone_B_on_off: any = '';
  Pump1_Zone_B_speed: any = '';

  Pump1_Zone_C_on_off: any = '';
  Pump1_Zone_C_speed: any = '';

  Primary_1_on_off: any = '';
  Primary_1_speed: any = '';

  Primary_2_on_off: any;
  Primary_2_speed: any = '';

  Condenser_1_on_off: any = '';
  Condenser_2_on_off: any = '';

  selectedStatuses: { [key: string]: string } = {
    Chiller1_status: '',
    Chiller1_evap_valve: '',
    Chiller1_cond_valve: '',

    Chiller2_status: '',
    Chiller2_evap_valve: '',
    Chiller2_cond_valve: '',

    Chiller3_status: '',
    Chiller3_evap_valve: '',
    Chiller3_cond_valve: '',

    Tower1_val: '',

    Tower1_fan1_on_off: '',
    Tower1_fan1_speed: '',
    Tower1_fan2_on_off: '',
    Tower1_fan2_speed: '',

    Tower3_val: '',

    Tower3_fan1_on_off: '',
    Tower3_fan1_speed: '',
    Tower3_fan2_on_off: '',
    Tower3_fan2_speed: '',

    Tower4_val: '',

    Tower4_fan1_on_off: '',
    Tower4_fan1_speed: '',
    Tower4_fan2_on_off: '',
    Tower4_fan2_speed: '',

    Pump1_Zone_A_on_off: '',
    Pump1_Zone_A_speed: '',

    Pump2_Zone_A_on_off: '',
    Pump2_Zone_A_speed: '',

    Pump1_Zone_B_on_off: '',
    Pump1_Zone_B_speed: '',

    Pump1_Zone_C_on_off: '',
    Pump1_Zone_C_speed: '',

    Primary_1_on_off: '',
    Primary_1_speed: '',

    Primary_2_on_off: '',
    Primary_2_speed: '',

    Condenser_1_on_off: '',
    Condenser_2_on_off: '',
  };

  showCommand() {
    if (this.command === 'bertlabs') {
      this.showDetails = true;
      this.onComponentChange();
    } else {
      this.errorMessage = 'Wrong command';

      alert('Wrong Command');
    }
  }

  onComponentChange() {
    switch (this.selectedComponent) {
      case 'Chillers':
        this.components = [
          { name: 'Chiller1', hasValves: true, hasCondValves: true },
          { name: 'Chiller2', hasValves: true, hasCondValves: true },
          { name: 'Chiller3', hasValves: true, hasCondValves: true },
        ];
        break;
      case 'Cooling Towers':
        this.components = [
          { name: 'Tower1', hasValves: false },
          { name: 'Tower3', hasValves: false },
          { name: 'Tower4', hasValves: false },
        ];
        break;
      case 'Condenser Pumps':
        this.components = [
          { name: 'Condenser_1', hasValves: false },
          { name: 'Condenser_2', hasValves: false },
        ];
        break;
      case 'Primary Pumps':
        this.components = [
          { name: 'Primary_1', hasValves: false },
          { name: 'Primary_2', hasValves: false },
        ];
        break;
      case 'Secondary Pumps':
        this.components = [
          { name: 'Pump1_Zone_A', hasValves: false },
          { name: 'Pump2_Zone_A', hasValves: false },
          { name: 'Pump1_Zone_B', hasValves: false },
          { name: 'Pump1_Zone_C', hasValves: false },
        ];
        break;
      default:
        this.components = [];
        break;
    }
  }

  clearSelection() {
    Object.keys(this.selectedStatuses).forEach((key) => {
      this.selectedStatuses[key] = '';
    });
  }
  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  sendCommand() {
    Object.keys(this.selectedStatuses).forEach((key) => {
      const value = this.selectedStatuses[key]; // Converting to lowercase for case-insensitive matching

      if (value === 'ON' || value === 'OPEN') {
        this.selectedStatuses[key] = '1';
      } else if (value === 'OFF' || value === 'CLOSE') {
        this.selectedStatuses[key] = '0';
      }
      // If none of the conditions match, the value remains unchanged
    });

    const data = {
      client: 'hul',
      site: 'ulhi',
      action: 4,
      id1: 'Chiller Plant/',
      body: {
        chiller1_on_off: this.selectedStatuses['Chiller1_status'],
        chiller1_cond_val: this.selectedStatuses['Chiller1_cond_valve'],
        chiller1_evap_val: this.selectedStatuses['Chiller1_evap_valve'],

        // Chiller 2
        chiller2_on_off: this.selectedStatuses['Chiller2_status'],
        chiller2_cond_val: this.selectedStatuses['Chiller2_cond_valve'],
        chiller2_evap_val: this.selectedStatuses['Chiller2_evap_valve'],

        // Chiller 3
        chiller3_on_off: this.selectedStatuses['Chiller3_status'],
        chiller3_cond_val: this.selectedStatuses['Chiller3_cond_valve'],
        chiller3_evap_val: this.selectedStatuses['Chiller3_evap_valve'],

        // Tower 1
        ct1_val: this.selectedStatuses['Tower1_val'],
        ct1_fan1_on_off: this.selectedStatuses['Tower1_fan1_on_off'],
        ct1_fan1_speed: this.selectedStatuses['Tower1_fan1_speed'],
        ct1_fan2_on_off: this.selectedStatuses['Tower1_fan2_on_off'],
        ct1_fan2_speed: this.selectedStatuses['Tower1_fan2_speed'],

        // Tower 3
        ct3_val: this.selectedStatuses['Tower3_val'],
        ct3_fan1_on_off: this.selectedStatuses['Tower3_fan1_on_off'],
        ct3_fan1_speed: this.selectedStatuses['Tower3_fan1_speed'],
        ct3_fan2_on_off: this.selectedStatuses['Tower3_fan2_on_off'],
        ct3_fan2_speed: this.selectedStatuses['Tower3_fan2_speed'],

        ct4_val: this.selectedStatuses['Tower4_val'],
        ct4_fan1_on_off: this.selectedStatuses['Tower4_fan1_on_off'],
        ct4_fan1_speed: this.selectedStatuses['Tower4_fan1_speed'],
        ct4_fan2_on_off: this.selectedStatuses['Tower4_fan2_on_off'],
        ct4_fan2_speed: this.selectedStatuses['Tower4_fan2_speed'],

        // Pump 1
        pp1_on_off: this.selectedStatuses['Primary_1_on_off'],
        pp1_speed: this.selectedStatuses['Primary_1_speed'],

        // Pump 2
        pp2_on_off: this.selectedStatuses['Primary_2_on_off'],
        pp2_speed: this.selectedStatuses['Primary_2_speed'],

        // Condenser Pump 1
        cp1_on_off: this.selectedStatuses['Condenser_1_on_off'],

        // Condenser Pump 2
        cp2_on_off: this.selectedStatuses['Condenser_1_on_off'],

        sp1_zoneA_on_off: this.selectedStatuses['Pump1_Zone_A_on_off'],
        sp1_zoneA_speed: this.selectedStatuses['Pump1_Zone_A_speed'],
        sp2_zoneA_on_off: this.selectedStatuses['Pump2_Zone_A_on_off'],
        sp2_zoneA_speed: this.selectedStatuses['Pump2_Zone_A_speed'],
        sp1_zoneB_on_off: this.selectedStatuses['Pump1_Zone_B_on_off'],
        sp1_zoneB_speed: this.selectedStatuses['Pump1_Zone_B_speed'],
        sp1_zoneC_on_off: this.selectedStatuses['Pump1_Zone_C_on_off'],
        sp1_zoneC_speed: this.selectedStatuses['Pump1_Zone_C_speed'],
      },
    };
    //console.log(this.selectedStatuses['Condenser_1_on_off']);
    this.isloading = true;
    //console.log(data);
    this.http
      .post(`${environment.apiBaseUrl}/auth/sendCommand`, data)
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isloading = false;
          //console.log(data);
          this.responseMessage = 'Command sent successfully!!';
          if (response.status == 'failed') {
            this.responseMessage =
              'Command sent successfully but there is error in input please try again!!';
            this.openSnackBar(response.msg);
          }
          //console.log('post response',response);
        },
        (error) => {
          this.isLoading = false;
          this.isloading = false;

          this.responseMessage = 'Failed to send command.please try again!';
          this.openSnackBar('failed to send command please try again');

          //console.log(data);
          //console.log('post response',error);
        }
      );
    this.clearSelection();
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }
}
