import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.css'],
})
export class CustomSnackbarComponent {
  isMinimized = false;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<CustomSnackbarComponent>,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  dismiss() {
    this.snackBarRef.dismiss();
    //console.log("snack bar broooooooo",this.data)
  }
  closeMessage(error: any) {
    // //console.log("oracleeeeeeeeeee",error);
    this.router.navigate(['dashboard/view-all-errors']);
    this.openSnackBar('Close from this page,so you can comment on this alarm');

    // error.isActive = false; // Set isClosed to true when the close button is clicked
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 10000,
    });
  }
  dismissAll() {
    this.snackBarRef.dismiss();
  }
  // closeAlarm(alarmId: number): void {
  //   const alarm = this.data.message.find(a => a.id === alarmId);
  //   if (alarm) {
  //     alarm.isActive = false;
  //   }
  // }
  toggleMinimize() {
    this.isMinimized = !this.isMinimized;
  }
}
