import { Component, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, timer } from 'rxjs';
import { Router } from '@angular/router';
// import { AuthService } from '../../services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'Login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  @ViewChild('form') form!: NgForm;
  email: string;
  password: string;
  isloading: boolean = false;

  usernameFormControl = new FormControl('', [Validators.required]);
  passwordFormControl = new FormControl('', [Validators.required]);
  matcher = new MyErrorStateMatcher();

  public $error = new BehaviorSubject<{
    message: string;
    status: number;
  } | null>(null);

  constructor(
    public authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private location: Location,
    private http: HttpClient
  ) {
    this.email = '';
    this.password = '';
  }

  type: string = 'password';
  isText: boolean = false;
  eyeIcon: string = 'fa-eye-slash';

  hideShowPass() {
    //console.log("Hide/show function triggered");
    this.isText = !this.isText;
    this.isText ? (this.eyeIcon = 'fa-eye') : (this.eyeIcon = 'fa-eye-slash');
    this.isText ? (this.type = 'text') : (this.type = 'password');
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  handleLogin(): void {
    const data = {
      username: this.email,
      password: this.password,
    };
    //console.log("dataaaaaa",data)
    //  if(this.email=="bertlabs" && this.password=="bert_admin"){
    //   sessionStorage.setItem('userRole','Admin');
    //   //console.log(sessionStorage.getItem('userRole'),"hiiiiiii")

    //   this.router.navigate(['./animated-home-screen']).then(() => {
    //     sessionStorage.setItem('accessToken', '1234@');
    //             setTimeout(() => {
    //               this.router.navigate(['./dashboard/home']);
    //               this.openSnackBar('Successfully Logged In');
    //             }, 5000);
    //           });
    //  }
    //  else {
    //   this.openSnackBar('Login failed. Please  try again.');
    //  }

    this.isloading = true;
    this.http.post<any>(`${environment.apiBaseUrl}/auth/login`, data).subscribe(
      (response: any) => {
        this.isloading = false;
        //console.log("Login response", response);

        // Check if the response contains a token
        if (response && response.access_token) {
          //console.log('hello')
          // Store the token and role in session storage
          sessionStorage.setItem('accessToken', response.access_token);
          // sessionStorage.setItem('userRole',response.role);
          sessionStorage.setItem('emailID', response.emailId),
            sessionStorage.setItem('username', response.username);
          // sessionStorage.setItem('userRole', response.role);
          sessionStorage.setItem('passwordsettime', response.passwordSetAt);
          // Navigate to the dashboard
          this.router.navigate(['./loading-screen']).then(() => {
            setTimeout(() => {
              //     if(response.isFirstTimeLogin){
              //       this.authService.setuserfirstime(true);
              // }
              this.router.navigate(['./dashboard/home']);

              this.openSnackBar('Successfully Logged In');
            }, 5000);
          });
        } else {
          this.resetLoginForm();

          // If no token is provided, show an error message
        }
      },
      (error: any) => {
        this.isloading = false;
        this.resetLoginForm();

        //console.log("Login error", error);
        this.openSnackBar(error.error.message);
      }
    );
  }

  ngOnInit(): void {
    // Prevent going back to animated screen from dashboard
    this.location.replaceState('/login');
  }
  resetLoginForm(): void {
    this.email = '';
    this.password = '';
    this.usernameFormControl.reset();
    this.passwordFormControl.reset();
    // sessionStorage.removeItem('userRole'); // Clear the selected role
  }
}
