import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ahu-types',
  templateUrl: './ahu-types.component.html',
  styleUrls: ['./ahu-types.component.css'],
})
export class AhuTypesComponent {
  @Input() data!: string;
  jsonData: any = {};

  // input form fields
  RAT: number = 0;
  AMBIENT_TEMP: number = 0;
  SAT: number = 0;
  CHW_VALVE: number = 0;
  FAN_SPEED: number = 0;
  STASP_ZONE_A: number = 0;

  // output form fields
  RAT_out: any = 0;
  CHW_VALVE_out: any = 0;
  POWER_out: any = 0;
  FAN_SPEED_out: any = 0;
  SDPT_out: any = 0;
  SAT_out: any = 0;

  RAT_in: any = 0;
  CHW_VALVE_in: any = 0;
  POWER_in: any = 0;
  FAN_SPEED_in: any = 0;
  SDPT_in: any = 0;
  SAT_in: any = 0;

  // KPI DATA
  KPI: number = 0;
  state: string = 'active';

  // variables inside
  predict_input: any = {};
  predict_response: any = {};
  predict_url: string = '';
  optimise_input: any = {};
  optimise_response: any = {};
  tag: string = '';
  model_no: number = 0;
  id: string = '';
  name: string = '';
  type: number = 1;
  isDisabled: boolean = false;
  capsule_group = 'input-group';
  stap_zone_label: string = '';
  predictionResult: any;
  interfaceResult: any;
  AMBIENT_TEMP_out: any = 0;

  cassandra_data: any = {};

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      const myVariable = params['id'];
      this.id = myVariable;
      // //console.log(myVariable);

      //console.log('id: ', this.id);

      // this.cassandraData(myVariable)

      try {
        this.data = String(localStorage.getItem(this.id));

        this.jsonData = JSON.parse(this.data);
        this.tag = this.jsonData['tag'];
        this.model_no = this.jsonData['model_no.'];
        this.name = this.jsonData['name'];
        this.type = this.jsonData['type'];
        this.isDisabled = this.jsonData['isDisabled'];
        this.stap_zone_label = this.jsonData['formLabelSTAP'];

        // if (this.isDisabled) {
        //   this.capsule_group = 'input-group2';
        // } else {
        //   this.capsule_group = 'input-group';
        // }

        // //console.log("data: ", this.data);
        //console.log('jsonData: ', this.jsonData);

        if (this.jsonData.hasOwnProperty('cassandra_data')) {
          this.cassandra_data = this.jsonData.cassandra_data;
        } else {
          this.cassandra_data = {};
        }
        this.cassandraData(this.id);

        // if (this.jsonData.isDisabled) {
        //   this.RAT_out = this.cassandra_data['rat'];
        //   this.CHW_VALVE_out = this.cassandra_data['chwv'];
        //   this.POWER_out = this.cassandra_data['power'];
        //   this.FAN_SPEED_out = this.cassandra_data['fan_speed'];
        //   this.SDPT_out = this.cassandra_data['sdpt'];
        //   this.SAT_out = this.cassandra_data['sat'];
        // }
        if (localStorage.getItem('interfaceResult')) {
          this.interfaceResult = localStorage.getItem('interfaceResult');
          this.interfaceResult = JSON.parse(this.interfaceResult);
          this.CHW_VALVE_in = this.interfaceResult[`chw_opening_${this.tag}`];
          this.RAT_in = this.interfaceResult[`rat_${this.tag}`];
          this.SAT_in = this.interfaceResult[`sat_${this.tag}`];
          this.SDPT_in = this.interfaceResult[`supply_dpt_${this.tag}`];
          this.FAN_SPEED_in = this.interfaceResult[`fan_speed_${this.tag}`];
          this.POWER_in = this.interfaceResult[`T_power_${this.tag}`];
        } else {
          this.CHW_VALVE_in = 0;
          this.RAT_in = 0;
          this.SAT_in = 0;
          this.SDPT_in = 0;
          this.FAN_SPEED_in = 0;
          this.POWER_in = 0;
        }
        if (!this.isDisabled) {
          // Check if the key exists
          if (this.jsonData.hasOwnProperty('predict_input')) {
            this.predict_input = this.jsonData['predict_input'];

            this.RAT = this.predict_input[`rat_${this.tag}`];
            this.AMBIENT_TEMP = this.predict_input['Ambient Temp'];
            this.SAT = this.predict_input[`Supply Air Temp_${this.tag}`];
            this.CHW_VALVE =
              this.predict_input[`chw_valve_opening_${this.tag}`];
            this.FAN_SPEED = this.predict_input[`fan_speed_${this.tag}`];
            this.STASP_ZONE_A =
              this.predict_input['Supply temp After Sec Pump Zone A'];
            this.model_no = this.predict_input['model_no.'];
          } else {
            this.RAT = 0;
            this.AMBIENT_TEMP = 0;
            this.SAT = 0;
            this.CHW_VALVE = 0;
            this.FAN_SPEED = 0;
            this.STASP_ZONE_A = 0;
          }

          if (localStorage.getItem('interfaceResult')) {
            this.interfaceResult = localStorage.getItem('interfaceResult');
            this.interfaceResult = JSON.parse(this.interfaceResult);
            this.RAT_in = this.interfaceResult[`rat_${this.tag}`];
            this.CHW_VALVE_in = this.interfaceResult[`chw_opening_${this.tag}`];
            this.POWER_in = this.interfaceResult[`T_power_${this.tag}`];
            this.FAN_SPEED_in = this.interfaceResult[`fan_speed_${this.tag}`];
            this.SDPT_in = this.interfaceResult[`supply_dpt_${this.tag}`];
            this.SAT_in = this.interfaceResult[`sat_${this.tag}`];
          } else {
            this.RAT_in = 0;
            this.CHW_VALVE_in = 0;
            this.POWER_in = 0;
            this.FAN_SPEED_in = 0;
            this.SDPT_in = 0;
            this.SAT_in = 0;
          }

          // interfaceResult
          if (localStorage.getItem('interfaceResult')) {
            this.interfaceResult = localStorage.getItem('interfaceResult');
            this.interfaceResult = JSON.parse(this.interfaceResult);
            this.CHW_VALVE_in = this.interfaceResult[`chw_opening_${this.tag}`];
            this.RAT_in = this.interfaceResult[`rat_${this.tag}`];
            this.SAT_in = this.interfaceResult[`sat_${this.tag}`];
            this.SDPT_in = this.interfaceResult[`supply_dpt_${this.tag}`];
            this.FAN_SPEED_in = this.interfaceResult[`fan_speed_${this.tag}`];
            this.POWER_in = this.interfaceResult[`T_power_${this.tag}`];
          } else {
            this.CHW_VALVE_in = 0;
            this.RAT_in = 0;
            this.SAT_in = 0;
            this.SDPT_in = 0;
            this.FAN_SPEED_in = 0;
            this.POWER_in = 0;
          }
          if (localStorage.getItem('predictionResult')) {
            this.predictionResult = localStorage.getItem('predictionResult');
            this.predictionResult = JSON.parse(this.predictionResult);
            this.CHW_VALVE_out =
              this.predictionResult[`chw_valve_opening_${this.tag}`];
            this.FAN_SPEED_out = this.predictionResult[`fan_speed_${this.tag}`];
            this.AMBIENT_TEMP_out = this.predictionResult['Ambient temp'];
          } else if (localStorage.getItem('optimise-response')) {
            this.optimise_response = localStorage.getItem('optimise-response');
            this.optimise_response = JSON.parse(this.optimise_response);

            this.predict_response = this.jsonData['predict_response'];
            this.RAT_out = this.predict_response[`rat_${this.tag}`];
            this.CHW_VALVE_out =
              this.optimise_response[`chw_valve_opening_${this.tag}`];
            this.POWER_out = this.optimise_response[`Power_${this.tag}`];
            this.FAN_SPEED_out =
              this.optimise_response[`fan_speed_${this.tag}`];
            this.SDPT_out = this.predict_response[`supply_dpt_${this.tag}`];
            this.SAT_out = this.predict_response[`Supply Air Temp_${this.tag}`];
          } else {
            if (this.jsonData.hasOwnProperty('predict_response')) {
              this.predict_response = this.jsonData['predict_response'];
              this.RAT_out = this.predict_response[`rat_${this.tag}`];
              this.CHW_VALVE_out = this.predict_response['CHW_VALVE'];
              this.POWER_out = this.predict_response[`Power_${this.tag}`];
              this.FAN_SPEED_out = this.predict_response['FAN_SPEED'];
              this.SDPT_out = this.predict_response[`supply_dpt_${this.tag}`];
              this.SAT_out =
                this.predict_response[`Supply Air Temp_${this.tag}`];
            } else {
              this.RAT_out = 0;
              this.CHW_VALVE_out = 0;
              this.POWER_out = 0;
              this.FAN_SPEED_out = 0;
              this.SDPT_out = 0;
              this.SAT_out = 0;
            }
          }
        }

        if (localStorage.getItem('kpi')) {
          this.KPI = Number(localStorage.getItem('kpi'));
        } else {
          this.KPI = 0;
        }

        if (this.jsonData.hasOwnProperty('predict_url')) {
          this.predict_url = this.jsonData['predict_url'];
        } else {
          this.predict_url = '';
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }

      if (this.jsonData.hasOwnProperty('cassandra_data')) {
        this.cassandra_data = this.jsonData.cassandra_data;
      } else {
        this.cassandra_data = {};
      }

      // Call the cassandraData function here and use the await keyword
      this.cassandraData(this.id).then(() => {
        if (this.jsonData.isDisabled) {
          this.RAT_out = this.cassandra_data['rat'];
          this.CHW_VALVE_out = this.cassandra_data['chwv'];
          this.POWER_out = this.cassandra_data['power'];
          this.FAN_SPEED_out = this.cassandra_data['fan_speed'];
          this.SDPT_out = this.cassandra_data['sdpt'];
          this.SAT_out = this.cassandra_data['sat'];
        }
      });
    });
  }

  ngOnInit(): void {
    this.getInputResult();
    this.getPredictionResult();
  }
  // http://hul-reinforcement:6100/get_prediction_ret
  async getPredictionResult() {
    this.http.get<any>('http://localhost:5000/get_prediction_result').subscribe(
      (response: { result: any }) => {
        try {
          this.predictionResult = response.result; // Directly assign the nested object
          // //console.log('Prediction result:', this.predictionResult);

          this.FAN_SPEED_out = this.predictionResult[`fan_speed_${this.tag}`];
          //console.log("FAN-Speed-out", this.FAN_SPEED_out);

          this.CHW_VALVE_out =
            this.predictionResult[`chw_valve_opening_${this.tag}`];
          this.jsonData['predictionResult'] = this.predictionResult;

          //console.log(this.jsonData);
          localStorage.setItem(
            'predictionResult',
            JSON.stringify(this.predictionResult)
          );
          //console.log('Optimised Output', this.predictionResult);
        } catch (error) {
          console.error('Error processing prediction result:', error);
        }
      },
      (error) => {
        console.error('Error fetching prediction result:', error);
      }
    );
  }

  async getInputResult() {
    this.http.get<any>('http://localhost:8080/get_result').subscribe(
      (response: { answer: any }) => {
        try {
          this.interfaceResult = response.answer; // Directly assign the nested object
          //console.log('Interface result:', this.interfaceResult);
          this.FAN_SPEED_in = this.interfaceResult[`fan_speed_${this.tag}`];
          //console.log("FAN-Speed-out", this.FAN_SPEED_in);
          this.POWER_in = this.interfaceResult[`T_power_${this.tag}`];
          this.CHW_VALVE_in = this.interfaceResult[`chw_opening_${this.tag}`];
          this.RAT_in = this.interfaceResult[`rat_${this.tag}`];
          this.SAT_in = this.interfaceResult[`sat_${this.tag}`];
          this.SDPT_in = this.interfaceResult[`supply_dpt_${this.tag}`];
          this.jsonData['interfaceResult'] = this.interfaceResult;
          // //console.log(this.jsonData);
          localStorage.setItem(
            'interfaceResult',
            JSON.stringify(this.interfaceResult)
          );
          // //console.log('Optimised Output', this.predictionResult);
        } catch (error) {
          console.error('Error processing interface result:', error);
        }
      },
      (error) => {
        console.error('Error fetching interface result:', error);
      }
    );
  }

  //   async getInputResult() {
  //     this.http.get<any>('http://localhost:8080/get_result')
  //      .subscribe(
  //        (response: { answer: string }) => {
  //          try {
  //            this.interfaceResult = JSON.parse(response.answer);
  //            //console.log('Interface result:', this.interfaceResult);
  //            this.FAN_SPEED_in = this.interfaceResult[`fan_speed_${this.tag}`];
  //            //console.log("FAN-Speed-out", this.FAN_SPEED_in);
  //            this.POWER_in = this.interfaceResult[`T_power_${this.tag}`];
  //            this.CHW_VALVE_in = this.interfaceResult[`chw_opening_${this.tag}`];
  //            this.RAT_in = this.interfaceResult[`rat_${this.tag}`];
  //            this.SAT_in = this.interfaceResult[`sat_${this.tag}`];
  //            this.SDPT_in = this.interfaceResult[`supply_dpt_${this.tag}`];
  //            this.jsonData['interfaceResult'] = this.interfaceResult;

  //            //console.log(this.jsonData);
  //            localStorage.setItem('interfaceResult', JSON.stringify(this.interfaceResult)); // Corrected line
  //            //console.log('Interface_output', this.interfaceResult )
  //          } catch (error) {
  //            console.error('Error parsing Interface result:', error);
  //          }
  //        },
  //        (error) => {
  //          console.error('Error fetching Interface result:', error);
  //        }
  //      );
  //  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
    if (this.RAT < 22 || this.RAT > 24) {
      this.openSnackBar('RAT should be in range [22,24]');
      return false;
    } else if (this.SAT < 11 || this.SAT > 22) {
      this.openSnackBar('SAT should be in range [11,22]');
      return false;
    } else if (this.AMBIENT_TEMP < 22 || this.AMBIENT_TEMP > 35) {
      this.openSnackBar('AMBIENT_TEMP should be in range [22,35]');
      return false;
    } else if (this.CHW_VALVE < 10 || this.CHW_VALVE > 100) {
      this.openSnackBar('CHW_VALVE should be in range [10,100]');
      return false;
    } else if (this.FAN_SPEED < 30 || this.FAN_SPEED > 100) {
      this.openSnackBar('FAN_SPEED should be in range [30,100]');
      return false;
    } else if (this.STASP_ZONE_A < 7 || this.STASP_ZONE_A > 12) {
      this.openSnackBar('STASP_ZONE_A should be in range [7,12]');
      return false;
    }
    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  async predictData() {
    if (this.validate()) {
      this.predict_input = {
        [`fan_speed_${this.tag}`]: Number(this.FAN_SPEED),
        [`rat_${this.tag}`]: Number(this.RAT),
        [`Supply Air Temp_${this.tag}`]: Number(this.SAT),
        [`chw_valve_opening_${this.tag}`]: Number(this.CHW_VALVE),
        'Ambient Temp': Number(this.AMBIENT_TEMP),
        'Supply temp After Sec Pump Zone A': Number(this.STASP_ZONE_A),
        'model_no.': Number(this.model_no),
      };

      //console.log('predict input: ', this.predict_input);

      try {
        const response = await this.http
          .post<any>(this.predict_url, this.predict_input, this.httpOptions)
          .toPromise();

        this.predict_response = response;
        this.predict_response['CHW_VALVE'] = Number(this.CHW_VALVE);
        this.predict_response['FAN_SPEED'] = Number(this.FAN_SPEED);
        //console.log('predict output', this.predict_response);

        this.RAT_out = this.predict_response[`rat_${this.tag}`];
        this.CHW_VALVE_out = this.predict_response['CHW_VALVE'];
        this.POWER_out = this.predict_response[`Power_${this.tag}`];
        this.FAN_SPEED_out = this.predict_response['FAN_SPEED'];
        this.SDPT_out = this.predict_response[`supply_dpt_${this.tag}`];
        this.SAT_out = this.predict_response[`Supply Air Temp_${this.tag}`];

        this.jsonData['predict_input'] = this.predict_input;
        this.jsonData['predict_response'] = this.predict_response;

        //console.log(this.jsonData);
        localStorage.setItem(this.id, JSON.stringify(this.jsonData));
        // //console.log(localStorage.getItem(this.id));

        this.openSnackBar('Predicted Succesfully');
      } catch (error) {
        console.error(
          'Error occurred while making predict API request:',
          error
        );
      }
    }
  }

  async cassandraData(id: string) {
    // const urlCassandra = ""
    const urlCassandra = 'https://bpsliveathul.bertlabs.com/cassandra_api';
    try {
      const response = await this.http
        .post<any>(urlCassandra, { id: id }, this.httpOptions)
        .toPromise();
      //console.log(response);
      this.jsonData['cassandra_data'] = response;
      // //console.log(this.jsonData)
      if (id == this.id) {
        localStorage.setItem(this.id, JSON.stringify(this.jsonData));
        this.cassandra_data = response;
      }
    } catch (error) {
      console.error('Error occurred while getting cassandra data: ', error);
    }
  }
}
