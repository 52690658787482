import { Component, ViewChild } from '@angular/core';
import { MyErrorStateMatcher } from '../login/login.component';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
// import { AuthService } from '/auth/auth.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent {
  @ViewChild('form') form!: NgForm;

  email: string;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new MyErrorStateMatcher();

  public forgotMode: boolean = false;
  public forgotModeSent: boolean = false;

  public $error = new BehaviorSubject<{
    message: string;
    status: number;
  } | null>(null);

  constructor(
    public authService: AuthService,
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {
    this.email = '';
  }

  toggleForgotMode() {
    this.forgotMode = !this.forgotMode;
    this.forgotModeSent = false;
  }

  handleSubmit() {
    if (this.forgotMode) {
      this.handlePasswordReset();
    } else {
      this.handleLogin();
    }
  }

  handlePasswordReset() {
    this.forgotModeSent = true;
    const { email } = this.form.value;
    //console.log(email);
    // server call to send reset link
  }

  handleLogin() {
    //console.log(this.email);
    const data = {
      emailId: this.email,
    };
    //console.log(data);
    this.http
      .post<any>(`${environment.apiBaseUrl}/auth/forgot-password`, data)
      .subscribe(
        (response) => {
          //console.log(response)
          this.snackBar.open('Password reset link sent to your email', 'OK', {
            duration: 2000,
          });
        },
        (error) => {
          //console.log(error);
          this.snackBar.open(error.error.message, 'OK', {
            duration: 2000,
          });
        }
      );
  }

  ngOnInit(): void {
    // this.authService.isAuthenticated().subscribe(isAuth => {
    //   // goto app if already logged in
    //   if (isAuth) this.router.navigate(['app']);
    // });
  }
}
