import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-secondary-pump',
  templateUrl: './secondary-pump.component.html',
  styleUrls: ['./secondary-pump.component.css'],
})
export class SecondaryPumpComponent {
  A1_VFD_GR: any = 0;
  A1_VFD_RED: any = 0;
  A2_VFD_GR: any = 0;
  A2_VFD_RED: any = 0;
  A3_VFD_GR: any = 0;
  A3_VFD_RED: any = 0;
  B1_VFD_GR: any = 0;
  B1_VFD_RED: any = 0;
  B2_VFD_GR: any = 0;
  B2_VFD_RED: any = 0;
  B3_VFD_GR: any = 0;
  B3_VFD_RED: any = 0;
  C1_VFD_GR: any = 0;
  C1_VFD_RED: any = 0;
  C2_VFD_GR: any = 0;
  C2_VFD_RED: any = 0;
  C3_VFD_GR: any = 0;
  C3_VFD_RED: any = 0;
  POWERA1_IN: any = 0;
  POWERA1_RED: any = 0;
  POWERA2_IN: any = 0;
  POWERA2_RED: any = 0;
  POWERA3_IN: any = 0;
  POWERA3_RED: any = 0;
  POWERB1_IN: any = 0;
  POWERB1_RED: any = 0;
  POWERB2_IN: any = 0;
  POWERB2_RED: any = 0;
  POWERB3_IN: any = 0;
  POWERB3_RED: any = 0;
  POWERC1_IN: any = 0;
  POWERC1_RED: any = 0;
  POWERC2_IN: any = 0;
  POWERC2_RED: any = 0;
  POWERC3_IN: any = 0;
  POWERC3_RED: any = 0;
  KPI: any = 0;
  envResult: any;
  optiResult: any;
  realResult: any;

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getInputResult();
    this.getPredictionResult();
    setInterval(() => {
      this.getInputResult();
      this.getPredictionResult();
    }, 600000);
  }

  // http://hul-reinforcement:6100/get_prediction_ret
  async getPredictionResult() {
    this.http
      .get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
      .subscribe(
        (response: { result: any }) => {
          try {
            this.optiResult = response.result; // Directly assign the nested object
            //green
            // //console.log('Prediction result:', this.predictionResult);

            // this.FAN_SPEED_out = this.optiResult[`fan_speed_${this.tag}`];
            // // //console.log("FAN-Speed-out", this.FAN_SPEED_out);
            this.A1_VFD_GR = this.optiResult['VFD Feedback Zone A Sec Pump 1'];
            this.A2_VFD_GR = this.optiResult['VFD Feedback Zone A Sec Pump 2'];
            this.A3_VFD_GR = this.optiResult['VFD Feedback Zone A Sec Pump 1'];
            this.B1_VFD_GR = this.optiResult['VFD Feedback Zone B Sec Pump 1'];
            this.B2_VFD_GR = this.optiResult['VFD Feedback Zone A Sec Pump 1'];
            this.B3_VFD_GR = this.optiResult['VFD Feedback Zone A Sec Pump 1'];
            this.C1_VFD_GR = this.optiResult['VFD Feedback Zone C Sec Pump 1'];
            this.C2_VFD_GR = this.optiResult['VFD Feedback Zone A Sec Pump 1'];
            this.C3_VFD_GR = this.optiResult['VFD Feedback Zone A Sec Pump 1'];
            // this.CHW_VALVE_out = this.optiResult[`chw_valve_opening_${this.tag}`];
            // this.jsonData['predictionResult'] = this.optiResult;

            // //console.log(this.jsonData);
            localStorage.setItem(
              'predictionResult',
              JSON.stringify(this.optiResult)
            );
            //console.log('Optimised Output', this.optiResult);
          } catch (error) {
            console.error('Error processing prediction result:', error);
          }
        },
        (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
            this.openSnackBar('Session expired');
          }
          console.error('Error fetching optimized result:', error);
          this.openSnackBar('error in fetching predicted values');
        }
      );
  }

  async getInputResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`).subscribe(
      (response: any) => {
        try {
          this.envResult = response.environment; // Directly assign the nested object
          //blue
          this.realResult = response.interface;

          //console.log('Interface result:', this.envResult);
          //console.log(this.realResult);
          this.POWERA1_IN = this.envResult[''];
          this.POWERA2_IN = this.envResult[''];
          this.POWERA3_IN = this.envResult[''];
          this.POWERB1_IN = this.envResult[''];
          this.POWERB2_IN = this.envResult[''];
          this.POWERB3_IN = this.envResult[''];
          this.POWERC1_IN = this.envResult[''];
          this.POWERC2_IN = this.envResult[''];
          this.POWERC3_IN = this.envResult[''];
          this.A1_VFD_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'VFD Feedback Sec Pump 1 Zone A'
            ];
          this.A2_VFD_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'VFD Feedback Sec Pump 1 Zone A'
            ];
          this.A3_VFD_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'VFD Feedback Sec Pump 1 Zone A'
            ];
          this.B1_VFD_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'VFD Feedback Sec Pump 1 Zone A'
            ];
          this.B2_VFD_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'VFD Feedback Sec Pump 1 Zone A'
            ];
          this.B3_VFD_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'VFD Feedback Sec Pump 1 Zone A'
            ];
          this.C1_VFD_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'VFD Feedback Sec Pump 1 Zone A'
            ];
          this.C2_VFD_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'VFD Feedback Sec Pump 1 Zone A'
            ];
          this.C3_VFD_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'VFD Feedback Sec Pump 1 Zone A'
            ];
          this.POWERA1_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'T_power_Zone_A_1'
            ];
          this.POWERA2_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'T_power_Zone_A_2'
            ];
          this.POWERA3_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'T_power_Zone_A_1'
            ];
          this.POWERB1_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'T_power_Zone_B'
            ];
          this.POWERB2_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'T_power_Zone_B'
            ];
          this.POWERB3_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'T_power_Zone_B'
            ];
          this.POWERC1_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'T_power_Zone_C'
            ];
          this.POWERC2_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'T_power_Zone_C'
            ];
          this.POWERC3_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'T_power_Zone_C'
            ];

          // this.FAN_SPEED_in = this.interfaceResult[`fan_speed_${this.tag}`];
          // //console.log("FAN-Speed-out", this.FAN_SPEED_in);
          // this.POWER_in = this.envResult[`Power_${this.tag}`];
          // // this.CHW_VALVE_in = this.interfaceResult[`chw_opening_${this.tag}`];
          // this.RAT_in = this.envResult[`rat_${this.tag}`];
          // this.SAT_in = this.envResult[`Supply Air Temp_${this.tag}`];
          // this.SDPT_in = this.envResult[`supply_dpt_${this.tag}`];
          // this.jsonData['interfaceResult'] = this.envResult;
          // // //console.log(this.jsonData);
          localStorage.setItem(
            'interfaceResult',
            JSON.stringify(this.envResult)
          );
          // //console.log('Optimised Output', this.predictionResult);
        } catch (error) {
          console.error('Error processing interface result:', error);
        }
      },
      (error) => {
        this.openSnackBar('error in fetching environment values');

        console.error('Error fetching interface result:', error);
      }
    );
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }
}
