import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-block-e',
  templateUrl: './block-e.component.html',
  styleUrls: ['./block-e.component.css'],
})
export class BlockEComponent {
  KPI: number = 0;
  basepower: number = 686.36;
  optiResult: any;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.getPredictionResult();
    setInterval(() => {
      this.getPredictionResult();
    }, 600000);
  }

  async getPredictionResult() {
    this.http
      .get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
      .subscribe(
        (response: any) => {
          try {
            this.optiResult = response.result; // Directly assign the nested object
            // const totpow=this.optiResult['Power_EAudi1']+this.optiResult['Power_EAudi2']+this.optiResult['Power_EDayE']+this.optiResult['Power_EDayW']+this.optiResult['Power_EGame']+this.optiResult['Power_ELounge'];
            // this.KPI=((this.basepower-(totpow))/(this.basepower))*100;

            var xeaudi1 = this.optiResult['fan_speed_EAudi1'] / 50;
            var eaudi1 = Math.pow(xeaudi1, 3);
            eaudi1 = (1 - eaudi1) * 100;
            //console.log(eaudi1);

            var xeaudi2 = this.optiResult['fan_speed_EAudi2'] / 50;
            var eaudi2 = Math.pow(xeaudi2, 3);
            eaudi2 = (1 - eaudi2) * 100;
            //console.log(eaudi2);

            var xedaye = this.optiResult['fan_speed_EDayE'] / 50;
            var edaye = Math.pow(xedaye, 3);
            edaye = (1 - edaye) * 100;
            //console.log(edaye);

            var xedayw = this.optiResult['fan_speed_EDayW'] / 50;
            var edayw = Math.pow(xedayw, 3);
            edayw = (1 - edayw) * 100;
            //console.log(edayw);

            var xegame = this.optiResult['fan_speed_EGame'] / 50;
            var egame = Math.pow(xegame, 3);
            egame = (1 - egame) * 100;
            //console.log(egame);

            var xelounge = this.optiResult['fan_speed_ELounge'] / 50;
            var elounge = Math.pow(xelounge, 3);
            elounge = (1 - elounge) * 100;
            //console.log(elounge);

            this.KPI = (elounge + egame + edaye + edayw + eaudi1 + eaudi2) / 6;
            this.KPI = this.KPI / -100;
          } catch (error) {
            console.error('Error processing prediction result:', error);
          }
        },
        (error) => {
          console.error('Error fetching optimized result:', error);
          this.openSnackBar('error in fetching predicted values');
        }
      );
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }
}
