import { Component, ViewChild } from '@angular/core';
import { MyErrorStateMatcher } from '../login/login.component';
import {
  FormControl,
  NgForm,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
// import { AuthService } from '/auth/auth.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css'],
})
export class ChangepasswordComponent {
  @ViewChild('form') form!: NgForm;

  email: string;
  showTooltip = false;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  isloading: boolean = false;

  matcher = new MyErrorStateMatcher();
  userForm: FormGroup;
  token: string = '';

  public forgotMode: boolean = false;
  public forgotModeSent: boolean = false;

  public $error = new BehaviorSubject<{
    message: string;
    status: number;
  } | null>(null);

  constructor(
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private http: HttpClient
  ) {
    this.userForm = this.fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(
              '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&].{8,}$'
            ),
          ],
        ],
        confirmPassword: ['', Validators.required],
      },
      { validator: this.passwordMatchValidator }
    );
    this.email = '';
  }

  toggleForgotMode() {
    this.forgotMode = !this.forgotMode;
    this.forgotModeSent = false;
  }

  passwordMatchValidator(group: FormGroup): any {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;

    return password === confirmPassword ? null : { mismatch: true };
  }
  isFormValid(): boolean | undefined {
    const passwordValid =
      !this.hasMinLengthError() &&
      !this.hasUppercaseError() &&
      !this.hasLowercaseError() &&
      !this.hasNumberError() &&
      !this.hasSpecialCharError();
    const passwordsMatch = !this.passwordsDoNotMatch();
    // //console.log(passwordValid,"  ",passwordsMatch)
    return passwordValid && passwordsMatch;
  }
  passwordsDoNotMatch(): boolean | undefined {
    return (
      this.userForm.hasError('mismatch') &&
      this.userForm.get('confirmPassword')?.dirty
    );
  }

  showvalid(): boolean {
    // return true;
    if (this.userForm.get('password')?.dirty) {
      if (this.userForm.get('password')?.value == '') {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  hasMinLengthError(): boolean {
    const control = this.userForm.get('password');
    // Check if the control is dirty and has an error, and if the password is not empty
    // //console.log(control);
    // //console.log(control?.dirty ,"hehe",control?.hasError('minlength'),"kj",control?.value !== "")
    // //console.log(!!(control?.dirty && control?.hasError('minlength') && control?.value !== ""))
    return !!(control?.dirty && control?.hasError('minlength'));
  }

  hasUppercaseError(): boolean {
    const control = this.userForm.get('password');
    return !!(
      control?.dirty &&
      control?.hasError('pattern') &&
      control?.value !== '' &&
      !/(?=.*[A-Z])/.test(control?.value ?? '')
    );
  }

  hasLowercaseError(): boolean {
    const control = this.userForm.get('password');
    return !!(
      control?.dirty &&
      control?.hasError('pattern') &&
      control?.value !== '' &&
      !/(?=.*[a-z])/.test(control?.value ?? '')
    );
  }

  hasNumberError(): boolean {
    const control = this.userForm.get('password');
    return !!(
      control?.dirty &&
      control?.hasError('pattern') &&
      control?.value !== '' &&
      !/(?=.*\d)/.test(control?.value ?? '')
    );
  }

  hasSpecialCharError(): boolean {
    const control = this.userForm.get('password');
    return !!(
      control?.dirty &&
      control?.hasError('pattern') &&
      control?.value !== '' &&
      !/(?=.*[@$#^!%*?&])/.test(control?.value ?? '')
    );
  }

  isPasswordValid() {
    const passwordValid =
      !this.hasMinLengthError() &&
      !this.hasUppercaseError() &&
      !this.hasLowercaseError() &&
      !this.hasNumberError() &&
      !this.hasSpecialCharError();
    // //console.log("ispasswordvalid",passwordValid);
    return passwordValid;
  }

  onSaveAndAddAnother() {
    if (this.userForm.valid) {
      // //console.log('User data:', this.userForm.value);
      alert('User added successfully! Add another user.');
      this.userForm.reset();
    }
  }

  onCancel() {
    this.router.navigate(['dashboard/viewusers']);
  }

  get password() {
    return this.userForm.get('password');
  }
  handleLogin() {
    // const { email, password } = this.form.value;
    // this.authService.logIn(email, password).subscribe({
    //   error: err => {
    //     this.$error.next(err);
    //     // resolve after 2500 ms
    //     setTimeout(() => {
    //       this.$error.next(null);
    //     }, 2500);
    //   }
    // });
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams['token'];
    if (this.token == undefined) {
      this.openSnackBar(
        'Error! Please try again,send reset password link again'
      );
    }

    // this.authService.isAuthenticated().subscribe(isAuth => {
    //   // goto app if already logged in
    //   if (isAuth) this.router.navigate(['app']);
    // });
  }
  get passwordTooltip() {
    return `
      <div>
        <div [ngClass]="{'valid': !hasMinLengthError(), 'invalid': hasMinLengthError()}">* Minimum 8 characters</div>
        <div [ngClass]="{'valid': !hasUppercaseError(), 'invalid': hasUppercaseError()}">* At least 1 uppercase letter</div>
        <div [ngClass]="{'valid': !hasLowercaseError(), 'invalid': hasLowercaseError()}">* At least 1 lowercase letter</div>
        <div [ngClass]="{'valid': !hasNumberError(), 'invalid': hasNumberError()}">* At least 1 number</div>
        <div [ngClass]="{'valid': !hasSpecialCharError(), 'invalid': hasSpecialCharError()}">* At least 1 special character</div>
      </div>
    `;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }
  onSubmit() {
    const data = {
      newPassword: this.userForm.get('password')?.value,
    }; // Get form values
    this.isloading = true;
    const apiUrl = `${environment.apiBaseUrl}/auth/reset-password`;
    const urlWithToken = `${apiUrl}?token=${this.token}`;
    this.http.post<any>(urlWithToken, data).subscribe(
      (resposne) => {
        this.isloading = false;
        //console.log(resposne);
        this.openSnackBar('Successfully Changed you password,Now login');
        this.router.navigate(['/login']);
      },
      (error) => {
        this.isloading = false;
        this.openSnackBar('Error! Please try again,password not changed');
      }
    );

    ////console.log(data,"you have landed on submit of change p");
  }
}
