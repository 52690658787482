import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ErrorMonitoringService } from 'src/app/services/error-monitoring.service';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';

@Component({
  selector: 'app-chiller',
  templateUrl: './chiller.component.html',
  styleUrls: ['./chiller.component.css'],
})
export class ChillerComponent {
  C1_POWER_env: any = 0;
  C1_POWER_RED: any = 0;
  C2_POWER_env: any = 0;
  C2_POWER_RED: any = 0;
  C3_POWER_env: any = 0;
  C3_POWER_RED: any = 0;
  C4_POWER_env: any = 0;
  C4_POWER_RED: any = 0;
  C1_W_SP: any = 0;
  C2_W_SP: any = 0;
  C3_W_SP: any = 0;
  C4_W_SP: any = 0;
  C1_W_SP_opti: any = 0;
  C2_W_SP_opti: any = 0;
  C3_W_SP_opti: any = 0;
  C4_W_SP_opti: any = 0;
  KPI: any = 0;
  basepowchw: any = 328.76;
  basepowsecpump: any = 36.42;
  basepow: any = 365.18;
  realResult: any;
  envResult: any;
  optiResult: any;
  alarmlog: any[] = [];

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private errorMonitoringService: ErrorMonitoringService
  ) {}

  ngOnInit(): void {
    this.viewalarmlogs();
    this.getInputResult();
    this.getPredictionResult();
    setInterval(() => {
      this.getInputResult();
      this.getPredictionResult();
    }, 600000);
  }
  async viewalarmlogs() {
    this.errorMonitoringService.alarms$.subscribe((alarms) => {
      this.alarmlog = alarms['Chiller Plant'];
      // //console.log("from servbicve",this.alarmlog)
      if (this.alarmlog.length > 0 && this.router.url == `/dashboard/chiller`) {
        this.openSnackBar1();
      }
    });
  }
  openSnackBar1() {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message: this.alarmlog },
      horizontalPosition: 'start',
      duration: undefined,
    });
  }

  // http://hul-reinforcement:6100/get_prediction_ret
  async getPredictionResult() {
    this.http
      .get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
      .subscribe(
        (response: { result: any }) => {
          try {
            this.optiResult = response.result; // Directly assign the nested object
            //green
            // //console.log('Prediction result:', this.predictionResult);
            this.C1_W_SP_opti = this.optiResult['Chilled Water Set Point'];
            this.C2_W_SP_opti = this.optiResult['Chilled Water Set Point'];
            this.C3_W_SP_opti = this.optiResult['Chilled Water Set Point'];
            this.C4_W_SP_opti = this.optiResult['Chilled Water Set Point'];

            // this.C1_POWER_env = this.optiResult['Chilled-water Power'];
            // this.C2_POWER_env = this.optiResult['Chilled-water Power'];
            // this.C3_POWER_env = this.optiResult['Chilled-water Power'];
            // this.C4_POWER_env = this.optiResult['Chilled-water Power'];

            // this.FAN_SPEED_out = this.optiResult[`fan_speed_${this.tag}`];
            // // //console.log("FAN-Speed-out", this.FAN_SPEED_out);
            // this.A1_VFD_GR=this.optiResult['VFD Feedback Zone A Sec Pump 1'];

            // this.CHW_VALVE_out = this.optiResult[`chw_valve_opening_${this.tag}`];
            // this.jsonData['predictionResult'] = this.optiResult;

            // //console.log(this.jsonData);
            localStorage.setItem(
              'predictionResult',
              JSON.stringify(this.optiResult)
            );
            //console.log('Optimised Output', this.optiResult);
          } catch (error) {
            console.error('Error processing prediction result:', error);
          }
        },
        (error) => {
          console.error('Error fetching optimized result:', error);
          this.openSnackBar('error in fetching predicted values');
        }
      );
  }

  async getInputResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`).subscribe(
      (response: any) => {
        try {
          this.envResult = response.environment; // Directly assign the nested object
          //blue
          this.realResult = response.interface;
          //console.log('Interface result:', this.envResult);
          this.C1_POWER_env = this.envResult['Chilled-water Power'];
          this.C2_POWER_env = this.envResult['Chilled-water Power'];
          this.C3_POWER_env = this.envResult['Chilled-water Power'];
          this.C4_POWER_env = this.envResult['Chilled-water Power'];
          this.C4_W_SP =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'Chilled Water Set Point'
            ];
          this.C3_W_SP =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'Chilled Water Set Point'
            ];
          this.C2_W_SP =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'Chilled Water Set Point'
            ];
          this.C1_W_SP =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data'][
              'Chilled Water Set Point'
            ];
          this.C4_POWER_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data']['sum_power'];
          //console.log(this.realResult['fd00::212:4b00:1957:d1af_1']['data'],"ytash");
          this.C3_POWER_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data']['sum_power'];
          this.C2_POWER_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data']['sum_power'];
          this.C1_POWER_RED =
            this.realResult['fd00::212:4b00:1957:d1af_1']['data']['sum_power'];
          var avgpow =
            this.envResult['Chilled-water Power'] +
            this.envResult['Total Sec Pump Power'];
          //console.log(avgpow,"hasr")
          this.KPI = ((this.basepow - avgpow) / this.basepow) * 100;
          this.KPI = this.KPI;
          // this.C3_POWER_IN = this.envResult['Chilled-water Power'];
          // this.POWERA1_IN=this.envResult[''];
          // this.POWER1_IN=this.envResult[''];
          // this.FAN_SPEED_in = this.interfaceResult[`fan_speed_${this.tag}`];
          // //console.log("FAN-Speed-out", this.FAN_SPEED_in);
          // this.POWER_in = this.envResult[`Power_${this.tag}`];
          // // this.CHW_VALVE_in = this.interfaceResult[`chw_opening_${this.tag}`];
          // this.RAT_in = this.envResult[`rat_${this.tag}`];
          // this.SAT_in = this.envResult[`Supply Air Temp_${this.tag}`];
          // this.SDPT_in = this.envResult[`supply_dpt_${this.tag}`];
          // this.jsonData['interfaceResult'] = this.envResult;
          // // //console.log(this.jsonData);
          localStorage.setItem(
            'interfaceResult',
            JSON.stringify(this.envResult)
          );
          // //console.log('Optimised Output', this.predictionResult);
        } catch (error) {
          console.error('Error processing interface result:', error);
        }
      },
      (error) => {
        this.openSnackBar('error in fetching environment values');

        console.error('Error fetching interface result:', error);
      }
    );
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }
}
