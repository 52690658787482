import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorMonitoringService } from 'src/app/services/error-monitoring.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  // output field initialization for form
  DPT_Higher: number = 0;
  DPT_Lower: number = 0;
  DPT_Maintained: number = 0;

  RAT_Higher: number = 0;
  RAT_Lower: number = 0;
  RAT_Maintained: number = 0;
  basepow: any = 366.84;
  // basepowchiller:any=
  Fan_Power_Higher: number = 0;
  Fan_Power_Lower: number = 0;
  Fan_Power_Maintained: number = 0;

  AHU_Fan_Power_Saving: number = 0;
  Chiller_Power_Saving: number = 0;
  Secondary_Pump_Power_Saving: number = 0;

  KPI: number = 0;
  KPIa: number = 0;
  KPIb1: number = 0;
  KPIb2e: number = 0;
  KPId4: number = 0;
  KPId1: number = 0;
  KPId3: number = 0;
  KPId2: number = 0;
  KPIe: number = 0;
  KPIb2w: number = 0;
  KPIf: number = 0;

  envResult: any;

  @Input() title: any;
  @Input() dynamicPath: any;
  heading: String = 'HOME';
  constructor(
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private errorMonitoringService: ErrorMonitoringService,
    private websocketservice: WebSocketService
  ) {
    this.http
      .get('assets/ahus.json', { responseType: 'text' })
      .subscribe((data) => {
        const ahusData = JSON.parse(data);

        for (const key in ahusData) {
          if (ahusData.hasOwnProperty(key) && !localStorage.getItem(key)) {
            localStorage.setItem(key, JSON.stringify(ahusData[key]));
          }
        }
      });

    if (localStorage.getItem('kpi')) {
      this.KPI = Number(localStorage.getItem('kpi'));
    } else {
      this.KPI = 0;
    }
  }

  ngOnInit(): void {
    this.getPredictionResult();
    setInterval(() => {
      this.getPredictionResult();
    }, 600000);
  }

  // async getPredictionResult() {
  //   this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
  //     .subscribe(
  //       (response: any) => {
  //         try {
  //           this.envResult = response.result; // Directly assign the nested object
  //           // const totpow=this.envResult['Power_B2WFC']+this.envResult['Power_B2WKK']+this.envResult['Power_B2WL1']+this.envResult['Power_B2WL2']+this.envResult['Power_B2WL3']+this.envResult['Power_B2WL4']+this.envResult['Power_B2WMR'];
  //           // this.KPI=((this.basepower-(totpow))/(this.basepower))*100;

  //           // var xb2wfc=(this.envResult['fan_speed_B2WFC'])/50;
  //           // var b2wfc=Math.pow(xb2wfc,3);
  //           // b2wfc=(1-b2wfc)*100;
  //           // //console.log(b2wfc);

  //           // var xb2wkk=(this.envResult['fan_speed_B2WKK'])/50;
  //           // var b2wkk=Math.pow(xb2wkk,3);
  //           // b2wkk=(1-b2wkk)*100;
  //           // //console.log(b2wkk);

  //           // var xb2wl1=(this.envResult['fan_speed_B2WL1'])/50;
  //           // var b2wl1=Math.pow(xb2wl1,3);
  //           // b2wl1=(1-b2wl1)*100;
  //           // //console.log(b2wl1);

  //           // var xb2wl2=(this.envResult['fan_speed_B2WL2'])/50;
  //           // var b2wl2=Math.pow(xb2wl2,3);
  //           // b2wl2=(1-b2wl2)*100;
  //           // //console.log(b2wl2);

  //           // var xb2wl3=(this.envResult['fan_speed_B2WL3'])/50;
  //           // var b2wl3=Math.pow(xb2wl3,3);
  //           // b2wl3=(1-b2wl3)*100;
  //           // //console.log(b2wl3);

  //           // var xb2wl4=(this.envResult['fan_speed_B2WL4'])/50;
  //           // var b2wl4=Math.pow(xb2wl4,3);
  //           // b2wl4=(1-b2wl4)*100;
  //           // //console.log(b2wl4);

  //           // var xb2wmr=(this.envResult['fan_speed_B2WMR'])/50;
  //           // var b2wmr=Math.pow(xb2wmr,3);
  //           // b2wmr=(1-b2wmr)*100;
  //           // //console.log(b2wmr);

  //           // var xal2=(this.envResult['fan_speed_AL2'])/50;
  //           // var al2=Math.pow(xal2,3);
  //           // al2=(1-al2)*100;
  //           // //console.log(al2);

  //           // var xamezz=(this.envResult['fan_speed_AMezz'])/50;
  //           // var amezz=Math.pow(xamezz,3);

  //           // amezz=(1-amezz)*100;
  //           // //console.log(amezz);
  //           // var xaub=(this.envResult['fan_speed_AUB'])/50;
  //           // var aub=Math.pow(xaub,3);

  //           // aub=(1-aub)*100;
  //           // //console.log(aub);
  //           // var xb1lo=(this.envResult['fan_speed_B1L0'])/50;
  //           // var b1lo=Math.pow(xb1lo,3);
  //           // b1lo=(1-b1lo)*100;
  //           // //console.log(b1lo);
  //           // this.KPIa=(al2+amezz+aub)/3;

  //           // var xb1gs=(this.envResult['fan_speed_B1GS'])/50;
  //           // var b1gs=Math.pow(xb1gs,3);
  //           // b1gs=(1-b1gs)*100;
  //           // //console.log(b1gs);

  //           // var xb1l1=(this.envResult['fan_speed_B1L1'])/50;
  //           // var b1l1=Math.pow(xb1l1,3);
  //           // b1l1=(1-b1l1)*100;
  //           // //console.log(b1l1);

  //           // // var xb1l2=(this.envResult['fan_speed_B1L2'])/50;
  //           // // var b1l2=Math.pow(xb1l2,3);
  //           // // b1l2=(1-b1l2)*100;
  //           // // //console.log(b1l2);

  //           // var xb1l3=(this.envResult['fan_speed_B1L3'])/50;
  //           // var b1l3=Math.pow(xb1l3,3);
  //           // b1l3=(1-b1l3)*100;
  //           // //console.log(b1l3);

  //           // var xb1l4=(this.envResult['fan_speed_B1L4'])/50;
  //           // var b1l4=Math.pow(xb1l4,3);
  //           // b1l4=(1-b1l4)*100;
  //           // //console.log(b1l4);
  //           // this.KPIb1=(b1lo+b1gs+b1l1+b1l3+b1l4)/6;

  //           // var xb2efc=(this.envResult['fan_speed_B2EFC'])/50;
  //           // var b2efc=Math.pow(xb2efc,3);
  //           // b2efc=(1-b2efc)*100;
  //           // //console.log(b2efc);

  //           // var xb2el1=(this.envResult['fan_speed_B2EL1'])/50;
  //           // var b2el1=Math.pow(xb2el1,3);
  //           // b2el1=(1-b2el1)*100;
  //           // //console.log(b2el1);

  //           // var xb2el2=(this.envResult['fan_speed_B2EL2'])/50;
  //           // var b2el2=Math.pow(xb2el2,3);
  //           // b2el2=(1-b2el2)*100;
  //           // //console.log(b2el2);

  //           // var xb2el3=(this.envResult['fan_speed_B2EL3'])/50;
  //           // var b2el3=Math.pow(xb2el3,3);
  //           // b2el3=(1-b2el3)*100;
  //           // //console.log(b2el3);

  //           // var xb2el4=(this.envResult['fan_speed_B2EL4'])/50;
  //           // var b2el4=Math.pow(xb2el4,3);
  //           // b2el4=(1-b2el4)*100;
  //           // //console.log(b2el4);

  //           // this.KPIb2e=(b2el4+b2el3+b2el2+b2el1+b2efc)/5;

  //           // var xd5gs=(this.envResult['fan_speed_D5GS'])/50;
  //           // var d5gs=Math.pow(xd5gs,3);
  //           // d5gs=(1-d5gs)*100;
  //           // //console.log(d5gs);

  //           // var xd4gs=(this.envResult['fan_speed_D4GS'])/50;
  //           // var d4gs=Math.pow(xd4gs,3);
  //           // d4gs=(1-d4gs)*100;
  //           // //console.log(d4gs);

  //           // var xd4l0=(this.envResult['fan_speed_D4L0'])/50;
  //           // var d4l0=Math.pow(xd4l0,3);
  //           // d4l0=(1-d4l0)*100;
  //           // //console.log(d4l0);

  //           // var xd4l1=(this.envResult['fan_speed_D4L1'])/50;
  //           // var d4l1=Math.pow(xd4l1,3);
  //           // d4l1=(1-d4l1)*100;
  //           // //console.log(d4l1);

  //           // var xd4l2=(this.envResult['fan_speed_D4L2'])/50;
  //           // var d4l2=Math.pow(xd4l2,3);
  //           // d4l2=(1-d4l2)*100;
  //           // //console.log(d4l2);

  //           // var xd4l4=(this.envResult['fan_speed_D4L4'])/50;
  //           // var d4l4=Math.pow(xd4l4,3);
  //           // d4l4=(1-d4l4)*100;
  //           // //console.log(d4l4);
  //           // this.KPId4=(d4l4+d4l2+d4l1+d4l0+d4gs)/5;

  //           // var xd1l0=(this.envResult['fan_speed_D1L0'])/50;
  //           // var d1l0=Math.pow(xd1l0,3);
  //           // d1l0=(1-d1l0)*100;
  //           // //console.log(d1l0);

  //           // var xd1l1=(this.envResult['fan_speed_D1L1'])/50;
  //           // var d1l1=Math.pow(xd1l1,3);
  //           // d1l1=(1-d1l1)*100;
  //           // //console.log(d1l1);

  //           // var xd1l2=(this.envResult['fan_speed_D1L2'])/50;
  //           // var d1l2=Math.pow(xd1l2,3);
  //           // d1l2=(1-d1l2)*100;
  //           // //console.log(d1l2);

  //           // var xd1l3=(this.envResult['fan_speed_D1L3'])/50;
  //           // var d1l3=Math.pow(xd1l3,3);
  //           // d1l3=(1-d1l3)*100;
  //           // //console.log(d1l3);

  //           // var xd1l4=(this.envResult['fan_speed_D1L4'])/50;
  //           // var d1l4=Math.pow(xd1l4,3);
  //           // d1l4=(1-d1l4)*100;
  //           // //console.log(d1l4);
  //           // this.KPId1=(d1l4+d1l3+d1l2+d1l1+d1l0)/5;

  //           // var xd3l0=(this.envResult['fan_speed_D3L0'])/50;
  //           // var d3l0=Math.pow(xd3l0,3);
  //           // d3l0=(1-d3l0)*100;
  //           // //console.log(d3l0);

  //           // var xd3l1=(this.envResult['fan_speed_D3L1'])/50;
  //           // var d3l1=Math.pow(xd3l1,3);
  //           // d3l1=(1-d3l1)*100;
  //           // //console.log(d3l1);

  //           // var xd3l2=(this.envResult['fan_speed_D3L2'])/50;
  //           // var d3l2=Math.pow(xd3l2,3);
  //           // d3l2=(1-d3l2)*100;
  //           // //console.log(d3l2);

  //           // var xd3l4=(this.envResult['fan_speed_D3L4'])/50;
  //           // var d3l4=Math.pow(xd3l4,3);
  //           // d3l4=(1-d3l4)*100;
  //           // //console.log(d3l4);
  //           // this.KPId3=(d3l4+d3l2+d3l1+d3l0)/4;
  //           // var xd2l0=(this.envResult['fan_speed_D2L0'])/50;
  //           // var d2l0=Math.pow(xd2l0,3);
  //           // d2l0=(1-d2l0)*100;
  //           // //console.log(d2l0);

  //           // var xd2l1=(this.envResult['fan_speed_D2L1'])/50;
  //           // var d2l1=Math.pow(xd2l1,3);
  //           // d2l1=(1-d2l1)*100;
  //           // //console.log(d2l1);

  //           // var xd2l2=(this.envResult['fan_speed_D2L2'])/50;
  //           // var d2l2=Math.pow(xd2l2,3);
  //           // d2l2=(1-d2l2)*100;
  //           // //console.log(d2l2);

  //           // var xd2l3=(this.envResult['fan_speed_D2L3'])/50;
  //           // var d2l3=Math.pow(xd2l3,3);
  //           // d2l3=(1-d2l3)*100;
  //           // //console.log(d2l3);

  //           // var xd2l4=(this.envResult['fan_speed_D2L4'])/50;
  //           // var d2l4=Math.pow(xd2l4,3);
  //           // d2l4=(1-d2l4)*100;
  //           // //console.log(d2l4);

  //           // this.KPId2=(d2l4+d2l3+d2l2+d2l1+d2l0)/5;

  //           // var xeaudi1=(this.envResult['fan_speed_EAudi1'])/50;
  //           // var eaudi1=Math.pow(xeaudi1,3);
  //           // eaudi1=(1-eaudi1)*100;
  //           // //console.log(eaudi1);

  //           // var xeaudi2=(this.envResult['fan_speed_EAudi2'])/50;
  //           // var eaudi2=Math.pow(xeaudi2,3);
  //           // eaudi2=(1-eaudi2)*100;
  //           // //console.log(eaudi2);

  //           // var xedaye=(this.envResult['fan_speed_EDayE'])/50;
  //           // var edaye=Math.pow(xedaye,3);
  //           // edaye=(1-edaye)*100;
  //           // //console.log(edaye);

  //           // var xedayw=(this.envResult['fan_speed_EDayW'])/50;
  //           // var edayw=Math.pow(xedayw,3);
  //           // edayw=(1-edayw)*100;
  //           // //console.log(edayw);

  //           // var xegame=(this.envResult['fan_speed_EGame'])/50;
  //           // var egame=Math.pow(xegame,3);
  //           // egame=(1-egame)*100;
  //           // //console.log(egame);

  //           // var xelounge=(this.envResult['fan_speed_ELounge'])/50;
  //           // var elounge=Math.pow(xelounge,3);
  //           // elounge=(1-elounge)*100;
  //           // //console.log(elounge);

  //           // this.KPIe=(elounge+egame+edaye+edayw+eaudi1+eaudi2)/6;
  //           // this.KPIb2w=(b2wmr+b2wl4+b2wl3+b2wl2+b2wl1+b2wkk+b2wfc)/7;
  //           // var xfl2tfa=(this.envResult['fan_speed_F_L2_TFA'])/50;
  //           // var fl2tfa=Math.pow(xfl2tfa,3);
  //           // fl2tfa=(1-fl2tfa)*100;
  //           // //console.log(fl2tfa);

  //           // var xfl3tfa=(this.envResult['fan_speed_F_L3_TFA'])/50;
  //           // var fl3tfa=Math.pow(xfl3tfa,3);
  //           // fl3tfa=(1-fl3tfa)*100;
  //           // //console.log(fl3tfa);

  //           // var xfl4tfa=(this.envResult['fan_speed_F_L4_TFA'])/50;
  //           // var fl4tfa=Math.pow(xfl4tfa,3);
  //           // fl4tfa=(1-fl4tfa)*100;
  //           // //console.log(fl4tfa);

  //           // var xflgab=(this.envResult['fan_speed_F_LG_A&B Wing'])/50;
  //           // var flgab=Math.pow(xflgab,3);
  //           // flgab=(1-flgab)*100;
  //           // //console.log(flgab);

  //           // var xflab=(this.envResult['fan_speed_F_L_A&B Wing'])/50;
  //           // var flab=Math.pow(xflab,3);
  //           // flab=(1-flab)*100;
  //           // //console.log(flab,"umaa");

  //           // var xflcd=(this.envResult['fan_speed_F_L_C&D Wing'])/50;
  //           // var flcd=Math.pow(xflcd,3);
  //           // flcd=(1-flcd)*100;
  //           // //console.log(flcd);

  //           // var xflgcd=(this.envResult['fan_speed_F_LG_C&D Wing'])/50;
  //           // var flgcd=Math.pow(xflgcd,3);
  //           // flgcd=(1-flgcd)*100;
  //           // //console.log(flgcd);

  //           // var xflglblr3=(this.envResult['fan_speed_F_LG_LB_LR 3'])/50;
  //           // var flglblr3=Math.pow(xflglblr3,3);
  //           // flglblr3=(1-flglblr3)*100;
  //           // //console.log(flglblr3);

  //           // var xflselr1=(this.envResult['fan_speed_F_L_SE_LR 1'])/50;
  //           // var flselr1=Math.pow(xflselr1,3);
  //           // flselr1=(1-flselr1)*100;
  //           // //console.log(flselr1);

  //           // var xfltmlr2=(this.envResult['fan_speed_F_L_TM_LR_2'])/50;
  //           // var fltmlr2=Math.pow(xfltmlr2,3);
  //           // fltmlr2=(1-fltmlr2)*100;
  //           // //console.log(fltmlr2);

  //           // var xflrec=(this.envResult['fan_speed_F_L_Rec'])/50;
  //           // var flrec=Math.pow(xflrec,3);
  //           // flrec=(1-flrec)*100;
  //           // //console.log(flrec);

  //           // var xflbacko=(this.envResult['fan_speed_F_L_Back_O'])/50;
  //           // var flbacko=Math.pow(xflbacko,3);
  //           // flbacko=(1-flbacko)*100;
  //           // //console.log(flbacko);

  //           // this.KPIf=(fl2tfa+fl3tfa+fl4tfa+flgab+flgcd+flab+flcd+flglblr3+flselr1+fltmlr2+flrec+flbacko)/12;
  //           // this.KPI=(this.KPIa+this.KPIb1+this.KPIb2e+this.KPId4+this.KPId1+this.KPId3+this.KPId2+this.KPIb2w)/8;
  //           // this.KPI=(this.KPI)/(-100);

  //         } catch (error) {
  //           console.error('Error processing prediction result:', error);
  //         }
  //       },
  //       (error) => {
  //         console.error('Error fetching optimized result:', error);
  //         if (error.status === 401) {
  //           // Redirect to the login page
  //           this.router.navigate(['/login']); // Adjust the path as necessary
  //           sessionStorage.removeItem('accessToken');
  //           this.openSnackBar('Session expired')

  //         }
  //         else{
  //           this.openSnackBar('error in fetching predicted values')

  //         }
  //       }
  //     );
  // }

  async getPredictionResult() {
    this.http
      .get<any>(`${environment.apiBaseUrl}/auth/get_result`)

      .subscribe(
        (response: any) => {
          try {
            this.envResult = response.environment; // Directly assign the nested object

            var totpow =
              this.envResult['Power_AL2'] +
              this.envResult['Power_AMezz'] +
              this.envResult['Power_AUB'] +
              this.envResult['Power_B1L0'] +
              this.envResult['Power_B1GS'] +
              this.envResult['Power_B1L1'] +
              this.envResult['Power_B1L3'] +
              this.envResult['Power_B1L4'] +
              this.envResult['Power_B2EFC'] +
              this.envResult['Power_B2EL1'] +
              this.envResult['Power_B2EL2'] +
              this.envResult['Power_B2EL3'] +
              this.envResult['Power_B2EL4'] +
              this.envResult['Power_B2WFC'] +
              this.envResult['Power_B2WKK'] +
              this.envResult['Power_B2WL1'] +
              this.envResult['Power_B2WL2'] +
              this.envResult['Power_B2WL3'] +
              this.envResult['Power_B2WL4'] +
              this.envResult['Power_B2WMR'] +
              this.envResult['Power_D3L0'] +
              this.envResult['Power_D3L1'] +
              this.envResult['Power_D3L2'] +
              this.envResult['Power_D3L4'] +
              this.envResult['Power_D5GS'] +
              this.envResult['Power_D4GS'] +
              this.envResult['Power_D4L0'] +
              this.envResult['Power_D4L1'] +
              this.envResult['Power_D4L2'] +
              this.envResult['Power_D4L4'] +
              this.envResult['Power_D1L0'] +
              this.envResult['Power_D1L1'] +
              this.envResult['Power_D1L2'] +
              this.envResult['Power_D1L3'] +
              this.envResult['Power_D1L4'] +
              this.envResult['Power_D2L0'] +
              this.envResult['Power_D2L1'] +
              this.envResult['Power_D2L2'] +
              this.envResult['Power_D2L3'] +
              this.envResult['Power_D2L4'];
            totpow = totpow / 40;
            totpow = totpow / 1000;
            var avgpow =
              this.envResult['Chilled-water Power'] +
              this.envResult['Total Sec Pump Power'];
            //console.log
            totpow = totpow + avgpow;

            this.KPI = ((this.basepow - totpow) / this.basepow) * 100;
            this.KPI = this.KPI;
          } catch (error) {
            console.error('Error processing prediction result:', error);
          }
        },

        (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
            this.openSnackBar('Session expired');
          }
          console.error('Error fetching optimized result:', error);

          this.openSnackBar('error in fetching predicted values');
        }
      );
  }

  level: string = String(localStorage.getItem('selectedLevel'))[1] || '0';
  routeToHall(hall: string) {
    this.router.navigate(['/dashboard/' + hall + '-hall']);
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }
}
