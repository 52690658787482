import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { LogoutDialogComponent } from '../logout-dialog/logout-dialog.component';
import { ErrorMonitoringService } from 'src/app/services/error-monitoring.service';

@Component({
  selector: 'Main-side-bar',
  templateUrl: './main-side-bar.component.html',
  styleUrls: ['./main-side-bar.component.css'],
})
export class MainSideBarComponent implements OnInit {
  @Output() titleEmmiter: EventEmitter<string> = new EventEmitter<string>();

  block: string = '';

  routing_paths: string[] = [];
  showNavbar: boolean = false;
  expanded: boolean = false;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private http: HttpClient,
    private errorMonitoringService: ErrorMonitoringService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkNavbarVisibility(activatedRoute);
      }
    });
  }

  private checkNavbarVisibility(route: ActivatedRoute): void {
    if (route.firstChild) {
      route.firstChild.url.subscribe((segments) => {
        //console.log("segments: ", segments);
        this.block = segments[0].path;
        //console.log("block name: ", this.block)

        let selectedItem = this.items.find(
          (item) => item.route === '/dashboard/' + this.block
        );
        //console.log("selected item: ", selectedItem)
        if (selectedItem) {
          selectedItem.expanded = true;
        }

        for (let item of this.items) {
          if (
            item.route != '/dashboard/' + this.block &&
            this.block.includes('block')
          ) {
            item.expanded = false;
          }
        }

        if (segments.length > 0 && segments[0].path === 'home') {
          this.showNavbar = false;
          for (var item of this.items) {
            item.expanded = false;
            if (item.hasOwnProperty('subComponents')) {
              for (var subComponent of item.subComponents) {
                subComponent.expanded = false;
              }
            }
          }
        } else {
          this.showNavbar = false; //change
        }
      });
      this.checkNavbarVisibility(route.firstChild);
    }
  }

  isToggled = false;

  selectedItem: any;
  disabledAhus: any = [
    'AL0',
    'AL3E',
    'AL3W',
    'B2WGS',
    'B2EGS',
    'B2ETFA',
    'Sodexo Canteen AHU',
    'D4L3',
  ];
  items: any[] = [
    {
      title: 'A Block',
      isExpandable: true,
      expanded: false,
      route: '/dashboard/block-a',
      subComponents: [
        {
          title: 'AUB',
          route: '/dashboard/ahu/AUB',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'AL0',
          route: '/dashboard/ahu/AL0',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'AMezz',
          route: '/dashboard/ahu/AMezz',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'AL2',
          route: '/dashboard/ahu/AL2',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'AL3E',
          route: '/dashboard/ahu/AL3E',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'AL3W',
          route: '/dashboard/ahu/AL3W',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
      ],
    },
    {
      title: 'B1 Block',
      isExpandable: true,
      expanded: false,
      route: '/dashboard/block-b1',
      subComponents: [
        {
          title: 'B1GS',
          route: '/dashboard/ahu/B1GS',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B1L0',
          route: '/dashboard/ahu/B1L0',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B1L1',
          route: '/dashboard/ahu/B1L1',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B1L2',
          route: '/dashboard/ahu/B1L2',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B1L3',
          route: '/dashboard/ahu/B1L3',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B1L4',
          route: '/dashboard/ahu/B1L4',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
      ],
    },
    {
      title: 'B2W Block',
      isExpandable: true,
      expanded: false,
      route: '/dashboard/block-b2w',
      subComponents: [
        {
          title: 'B2WMR',
          route: '/dashboard/ahu/B2WMR',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B2WKK',
          route: '/dashboard/ahu/B2WKK',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B2WGS',
          route: '/dashboard/ahu/B2WGS',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B2WFC',
          route: '/dashboard/ahu/B2WFC',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B2WL1',
          route: '/dashboard/ahu/B2WL1',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B2WL2',
          route: '/dashboard/ahu/B2WL2',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B2WL3',
          route: '/dashboard/ahu/B2WL3',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B2WL4',
          route: '/dashboard/ahu/B2WL4',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
      ],
    },
    {
      title: 'B2E Block',
      isExpandable: true,
      expanded: false,
      route: '/dashboard/block-b2e',
      subComponents: [
        {
          title: 'B2EGS',
          route: '/dashboard/ahu/B2EGS',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B2EFC',
          route: '/dashboard/ahu/B2EFC',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B2ETFA',
          route: '/dashboard/ahu/B2ETFA',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B2EL1',
          route: '/dashboard/ahu/B2EL1',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B2EL2',
          route: '/dashboard/ahu/B2EL2',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B2EL3',
          route: '/dashboard/ahu/B2EL3',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'B2EL4',
          route: '/dashboard/ahu/B2EL4',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'Sodexo Canteen AHU',
          route: '/dashboard/ahu/B2WUB',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
      ],
    },
    {
      title: 'D1 Block',
      isExpandable: true,
      expanded: false,
      route: '/dashboard/block-d1',
      subComponents: [
        {
          title: 'D1L0',
          route: '/dashboard/ahu/D1L0',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'D1L1',
          route: '/dashboard/ahu/D1L1',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'D1L2',
          route: '/dashboard/ahu/D1L2',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'D1L3',
          route: '/dashboard/ahu/D1L3',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'D1L4',
          route: '/dashboard/ahu/D1L4',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
      ],
    },
    {
      title: 'D2 Block',
      isExpandable: true,
      expanded: false,
      route: '/dashboard/block-d2',
      subComponents: [
        {
          title: 'D2L0',
          route: '/dashboard/ahu/D2L0',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'D2L1',
          route: '/dashboard/ahu/D2L1',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'D2L2',
          route: '/dashboard/ahu/D2L2',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'D2L3',
          route: '/dashboard/ahu/D2L3',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'D2L4',
          route: '/dashboard/ahu/D2L4',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
      ],
    },
    {
      title: 'D3 Block',
      isExpandable: true,
      expanded: false,
      route: '/dashboard/block-d3',
      subComponents: [
        {
          title: 'D3L0',
          route: '/dashboard/ahu/D3L0',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'D3L1',
          route: '/dashboard/ahu/D3L1',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'D3L2',
          route: '/dashboard/ahu/D3L2',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'D3L4',
          route: '/dashboard/ahu/D3L4',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
      ],
    },
    {
      title: 'D4 Block',
      isExpandable: true,
      expanded: false,
      route: '/dashboard/block-d4',
      subComponents: [
        {
          title: 'D4GS',
          route: '/dashboard/ahu/D4GS',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'D4L0',
          route: '/dashboard/ahu/D4L0',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'D4L1',
          route: '/dashboard/ahu/D4L1',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'D4L2',
          route: '/dashboard/ahu/D4L2',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'D4L3',
          route: '/dashboard/ahu/D4L3',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'D4L4',
          route: '/dashboard/ahu/D4L4',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
      ],
    },
    {
      title: 'D5 Block',
      isExpandable: true,
      expanded: false,
      route: '/dashboard/block-d5',
      subComponents: [
        {
          title: 'D5GS',
          route: '/dashboard/ahu/D5GS',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
      ],
    },
    {
      title: 'E Block',
      isExpandable: true,
      expanded: false,
      disabled: true,
      route: '/dashboard/block-e',
      subComponents: [
        {
          title: 'EDayE',
          route: '/dashboard/ahu/EDayE',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'EDayW',
          route: '/dashboard/ahu/EDayW',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'ERec ',
          route: '/dashboard/ahu/ERec',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'EGame',
          route: '/dashboard/ahu/EGame',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'ELounge',
          route: '/dashboard/ahu/ELounge',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'EL2Lobby',
          route: '/dashboard/ahu/EL2Lobby',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'EL3Lobby',
          route: '/dashboard/ahu/EL3Lobby',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'ESquash',
          route: '/dashboard/ahu/ESquash',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'EAudi 1',
          route: '/dashboard/ahu/EAudi1',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'EAudi 2',
          route: '/dashboard/ahu/EAudi2',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
      ],
    },
    {
      title: 'F Block',
      isExpandable: true,
      expanded: false,
      disabled: true,
      route: '/dashboard/block-f',
      subComponents: [
        {
          title: 'A & B Wing-LG AHU',
          route: '/dashboard/ahu/F_LG_A&B Wing',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'C & D Wing-LG AHU',
          route: '/dashboard/ahu/F_LG_C&D Wing',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'Life Boy Training Hall (LR 3) AHU -B Wing-LG AHU',
          route: '/dashboard/ahu/F_LG_LB_LR 3',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'A&B Wing-L AHU',
          route: '/dashboard/ahu/F_L_A&B Wing',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'C&D Wing--L AHU',
          route: '/dashboard/ahu/F_L_C&D Wing',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'Serf Excle Training Hall (LR 1) AHU-B Wing-L AHU',
          route: '/dashboard/ahu/F_L_SE_LR 1',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'Taj Mahal Training Hall (LR 2) AHU-C Wing-L AHU',
          route: '/dashboard/ahu/F_L_TM_LR_2',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'Reception-L AHU',
          route: '/dashboard/ahu/F_L_Rec',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'Back Office-L AHU',
          route: '/dashboard/ahu/F_L_Back_O',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'TFA-Second AHU',
          route: '/dashboard/ahu/F_L2_TFA',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'TFA-Third AHU',
          route: '/dashboard/ahu/F_L3_TFA',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'TFA-Fourth AHU',
          route: '/dashboard/ahu/F_L4_TFA',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'Chiller(AC)',
          route: '/dashboard/f-chiller-plant',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
      ],
    },
    {
      title: 'Chiller Plant',
      isExpandable: true,
      expanded: false,
      subComponents: [
        {
          title: 'Primary Pump',
          route: '/dashboard/primary-pump',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'Chiller',
          route: '/dashboard/chiller',
          disabled: false,
          isExpandable: true,
          expanded: false,
          subSubComponents: [
            {
              title: 'Chiller1',
              route: '/dashboard/chiller1',
              disabled: false,
              isExpandable: false,
              expanded: false,
            },
            {
              title: 'Chiller2',
              route: '/dashboard/chiller2',
              disabled: false,
              isExpandable: false,
              expanded: false,
            },
            {
              title: 'Chiller3',
              route: '/dashboard/chiller3',
              disabled: false,
              isExpandable: false,
              expanded: false,
            },
            {
              title: 'Chiller4',
              route: '/dashboard/chiller4',
              disabled: false,
              isExpandable: false,
              expanded: false,
            },
          ],
        },
        {
          title: 'Cooling Tower',
          route: '/dashboard/cooling-tower',
          disabled: false,
          isExpandable: true,
          expanded: false,
          subSubComponents: [
            {
              title: 'Cooling tower 1',
              route: '/dashboard/cooling-tower1',
              disabled: false,
              isExpandable: false,
              expanded: false,
            },
            {
              title: 'Cooling tower 2',
              route: '/dashboard/cooling-tower2',
              disabled: false,
              isExpandable: false,
              expanded: false,
            },
            {
              title: 'Cooling tower 3',
              route: '/dashboard/cooling-tower3',
              disabled: false,
              isExpandable: false,
              expanded: false,
            },
            {
              title: 'Cooling tower 4',
              route: '/dashboard/cooling-tower4',
              disabled: false,
              isExpandable: false,
              expanded: false,
            },
          ],
        },
        {
          title: 'Condenser Pump',
          route: '/dashboard/condenser-pump',
          disabled: false,
          isExpandable: false,
          expanded: false,
        },
        {
          title: 'Secondary Pump',
          route: '/dashboard/secondary-pump',
          disabled: false,
          isExpandable: false,
          // expanded: false,
          // subSubComponents: [
          //   { title: 'Zone A', route: '/dashboard/zone-a', disabled: false, isExpandable: false, expanded: false, },
          //   { title: 'Zone B', route: '/dashboard/zone-b', disabled: false, isExpandable: false, expanded: false, },
          //   { title: 'Zone C', route: '/dashboard/zone-c', disabled: false, isExpandable: false, expanded: false, }
          // ]
        },
      ],
    },
  ];

  toggleExpanded(item: any) {
    item.expanded = !item.expanded;
  }

  isSelectedDescription(item: any, description: any): boolean {
    return this.selectedItem === item && item.expanded;
  }

  toggleButton(event: any): void {
    this.isToggled = event.source.checked;
  }

  goback() {
    this.location.back();
    this.isToggled = false;
  }

  handleItemClick(subComponent: any): void {
    //console.log('Clicked on:', subComponent);

    if (this.routing_paths.includes(subComponent.route)) {
      // Navigate to the route
      this.router.navigate([subComponent.route]);
    }
  }

  updateDisabledStatus() {
    for (const item of this.items) {
      for (const subComponent of item.subComponents) {
        // //console.log(subComponent.route);
        // //console.log(this.routing_paths.includes(subComponent.route));
        subComponent.disabled = !this.routing_paths.includes(
          subComponent.route
        );
      }
    }
  }
  getnoalarms() {
    return this.errorMonitoringService.getNumberOfAlarms();
  }
  async loadRoutingPaths() {
    try {
      const data = await this.http
        .get('assets/ahus.json', { responseType: 'text' })
        .toPromise();
      const ahusData = JSON.parse(String(data));

      for (const key in ahusData) {
        if (ahusData.hasOwnProperty(key)) {
          this.routing_paths.push('/dashboard/ahu/' + key);
        }
      }
      this.routing_paths.push('/dashboard/cooling-tower');
      this.routing_paths.push('/dashboard/primary-pump');
      this.routing_paths.push('/dashboard/secondary-pump');
      this.routing_paths.push('/dashboard/chiller');
      this.routing_paths.push('/dashboard/condenser-pump');
      this.routing_paths.push('/dashboard/f-chiller-plant');
      //console.log(this.routing_paths);
    } catch (error) {
      console.error('Error loading routing paths:', error);
    }
  }

  isdisabled(subComponent: any): boolean {
    return this.disabledAhus.includes(subComponent.title);
  }

  showLogoutDialog() {
    // const dialogRef = this.dialog.open(LogoutDialogComponent, {
    //   width: '100%',
    //   height:'100%'
    // });

    // dialogRef.afterClosed().subscribe((result) => {
    //   //console.log('The dialog was closed');
    // });
    this.router.navigate(['/dashboard/logout']);
  }

  async ngOnInit() {
    await this.loadRoutingPaths();
    this.updateDisabledStatus();
  }
}
