import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ErrorMonitoringService } from 'src/app/services/error-monitoring.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
@Component({
  selector: 'app-f-chiller-plant',
  templateUrl: './f-chiller-plant.component.html',
  styleUrls: ['./f-chiller-plant.component.css'],
})
export class FChillerPlantComponent {
  CH_WATER_IN_real: any = 0;
  CH_WATER_OUT_real: any = 0;
  AC_POWER_GR: any = 0;
  AC_POWER_real: any = 0;
  jsonData: any = {};
  @Input() data!: string;
  predictionResult: any;
  optiResult: any;
  interfaceResult: any;
  envResult: any;
  realResult: any;
  AC_CHILLER_SP: any = 0;
  AC_CHILLER_SP_GR: any = 0;
  KPI: any = 0;
  tag: string = '';
  model_no: number = 0;
  id: string = '';
  name: string = '';
  alarmlog: any[] = [];

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private webSocketService: WebSocketService,
    private errorMonitoringService: ErrorMonitoringService
  ) {
    this.route.params.subscribe((params) => {
      const myVariable = params['id'];
      this.id = myVariable;
      // // //console.log(myVariable);
      // //console.log('id: ', this.id);
      try {
        this.data = String(localStorage.getItem(this.id));

        this.jsonData = JSON.parse(this.data);
        this.tag = this.jsonData['tag'];
        this.model_no = this.jsonData['model_no.'];
        this.name = this.jsonData['name'];
      } catch (error) {}
    });
  }
  ngOnInit(): void {
    this.viewalarmlogs();
    this.getInputResult();
    this.getPredictionResult();
    this.webSocketService.ahuData$.subscribe((data) => {
      // Get data for AHU_01

      // //console.log("hello ahu1",data);
      const ahuData = data[`${this.tag}`];
      if (ahuData) {
        // //console.log('AHU_01 Data:', ahuData);
      }
    });

    setInterval(() => {
      this.getInputResult();
      this.getPredictionResult();
    }, 600000);
  }
  async viewalarmlogs() {
    this.errorMonitoringService.alarms$.subscribe((alarms) => {
      this.alarmlog = alarms[`${this.tag}`];
      // //console.log("from servbicve",this.alarmlog)
      if (
        this.alarmlog.length > 0 &&
        this.router.url == `/dashboard/ahu/${this.tag}`
      ) {
        this.openSnackBar1();
      }
    });
  }
  openSnackBar1() {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message: this.alarmlog },
      horizontalPosition: 'start',
      duration: undefined,
    });
  }
  async getPredictionResult() {
    this.http
      .get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
      .subscribe(
        (response: { result: any }) => {
          try {
            this.optiResult = response.result; // Directly assign the nested object
            // // //console.log('Prediction result:', this.predictionResult);
            this.AC_CHILLER_SP_GR =
              this.optiResult['Chilled Water Set Point_aircooledchiller'];
            this.jsonData['predictionResult'] = this.optiResult;

            // //console.log(this.jsonData);
            localStorage.setItem(
              'predictionResult',
              JSON.stringify(this.optiResult)
            );
            // //console.log('Optimised Output', this.optiResult);
          } catch (error) {
            console.error('Error processing prediction result:', error);
          }
        },
        (error) => {
          console.error('Error fetching optimized result:', error);
          this.openSnackBar('error in fetching predicted values');
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
            this.openSnackBar('Session expired');
          }
        }
      );
  }

  async getInputResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`).subscribe(
      (response: any) => {
        try {
          this.envResult = response.environment; // Directly assign the nested object
          this.realResult = response.interface;
          this.AC_POWER_real =
            this.realResult['fd00::212:4b00:1889:e010_1']['data'][
              'Total Chiller Power'
            ];
          this.AC_CHILLER_SP =
            this.realResult['fd00::212:4b00:1889:e010_1']['data'][
              'Chilled Water Set Point'
            ];
          // //console.log(this.aliasMap,"vijayyyy");
          // //console.log(this.realResult[this.aliasMap[`${this.tag}`]],"umaaaaaa");
          this.CH_WATER_IN_real =
            this.realResult['fd00::212:4b00:1889:e010_1']['data'][
              'Chilled Water Set Point'
            ];
          this.CH_WATER_OUT_real =
            this.realResult['fd00::212:4b00:1889:e010_1']['data'][
              'Mass Averaged Temperature (Outlet); Part Evap_waterside'
            ];

          this.jsonData['interfaceResult'] = this.envResult;
          // // //console.log(this.jsonData);
          localStorage.setItem(
            'interfaceResult',
            JSON.stringify(this.envResult)
          );
          // // //console.log('Optimised Output', this.predictionResult);
        } catch (error) {
          console.error('Error processing interface result:', error);
        }
      },
      (error) => {
        this.openSnackBar('error in fetching environment values');
        if (error.status === 401) {
          // Redirect to the login page
          this.router.navigate(['/login']); // Adjust the path as necessary
          sessionStorage.removeItem('accessToken');
          this.openSnackBar('Session expired');
        }
        console.error('Error fetching interface result:', error);
      }
    );
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }
}
