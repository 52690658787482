import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-ahuschedule-command',
  templateUrl: './ahuschedule-command.component.html',
  styleUrls: ['./ahuschedule-command.component.css'],
})
export class AhuscheduleCommandComponent {
  command: string = '';
  showDetails: boolean = false;
  errorMessage: string = '';
  selectedBlock: string = ''; // Default block
  selectedAHU: string = '';
  isloading: boolean = false;
  starthour: number = 0;
  startmin: number = 0;
  endhour: number = 0;
  responseMessage: string = '';
  endmin: number = 0;
  schedulecontrol: any;
  schedule_id: number = 0;
  isLoading: boolean = false;
  blocks: string[] = [
    'A',
    'B1',
    'B2W',
    'B2E',
    'D1',
    'D2',
    'D3',
    'D4',
    'D5',
    'E',
    'F',
  ];
  ahus: string[][] = [
    ['UB', 'Mezz', 'L2'],
    ['GS', 'L0', 'L1', 'L2', 'L3', 'L4'],
    ['MR', 'KK', 'FC', 'L1', 'L2', 'L3', 'L4'],
    ['FC', 'L1', 'L2', 'L3', 'L4'],
    ['L0', 'L1', 'L2', 'L3', 'L4'],
    ['L0', 'L1', 'L2', 'L3', 'L4'],
    ['L0', 'L1', 'L2', 'L4'],
    ['GS', 'L0', 'L1', 'L2', 'L3', 'L4'],
    ['GS'],
    ['DayE', 'DayW', 'Game', 'Lounge', 'Audi1', 'Audi2'],
    [
      'A&B Wing GF Corridor AHU',
      'C Wing GF Corridor-Admin AHU',
      'B Wing GF TR Hall AHU',
      'A&B Wing 1st Corridor',
      'B&C Wing 1st Corridor',
      'B Wing 1F TR Hall AHU',
      'C Wing 1F TR Hall AHU',
      'A Wing 1F Reception AHU',
      'A Wing 1F Reception & Back Office',
      'TFA-Second Floor',
      'TFA-Third Floor',
      'TFA-Fourth Floor',
    ],
  ];
  blockahumap: Map<string, number> = new Map<string, number>();
  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}
  ngOnInit(): void {
    for (let i = 0; i < this.blocks.length; i++) {
      this.blockahumap.set(this.blocks[i], i);
    }

    //console.log(this.blockahumap);
    //console.log(this.blockahumap.get("A"),"helllll");
    //console.log(this.selectedBlock);
  }
  showCommand() {
    if (this.command === 'bertlabs') {
      this.showDetails = true;
      this.errorMessage = '';
    } else {
      this.errorMessage = 'Wrong command';
      this.showDetails = false;
    }
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }
  sendCommand() {
    this.isloading = true;

    const data = {
      client: 'hul',
      site: 'ulhi',
      action: 3,
      id1: this.selectedBlock,
      // id2: this.selectedfloor,
      id2: this.selectedAHU,
      user: 'bert',
      body: {
        scheduleId: this.schedule_id,
        startHr: this.starthour,
        startMin: this.startmin,
        stopHr: this.endhour,
        stopMin: this.endmin,
        schControl: this.schedulecontrol,
      },
    };
    //console.log(data);

    this.http
      .post(`${environment.apiBaseUrl}/auth/sendCommand`, data)
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isloading = false;

          this.responseMessage = 'Command sent successfully!!';
          if (response.status == 'failed') {
            this.responseMessage =
              'Command sent successfully but there is error in input please try again!!';
            this.openSnackBar(response.msg);
          }
          //console.log("schedule data",data)
          //console.log('post response',response);
        },
        (error) => {
          this.isloading = false;

          this.isLoading = false;
          this.responseMessage = 'Failed to send command.please try again!';
          this.openSnackBar('failed to send command please try again');

          //console.log('post response',error);
        }
      );
  }
}
