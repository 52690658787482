import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-block-b-two-east',
  templateUrl: './block-b-two-east.component.html',
  styleUrls: ['./block-b-two-east.component.css'],
})
export class BlockBTwoEastComponent {
  KPI: number = 0;
  basepower: number = 2025.92;
  optiResult: any;
  isloading: boolean = false;

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getPredictionResult();
    setInterval(() => {
      this.getPredictionResult();
    }, 600000);
  }

  // async getPredictionResult() {
  //   this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
  //     .subscribe(
  //       (response: any) => {
  //         try {
  //           this.optiResult = response.result; // Directly assign the nested object
  //           //const totpow=this.optiResult['Power_B2EFC']+this.optiResult['Power_B2EL1']+this.optiResult['Power_B2EL2']+this.optiResult['Power_B2EL3']+this.optiResult['Power_B2EL4'];
  //           //this.KPI=((this.basepower-(totpow))/(this.basepower))*100;

  //           var xb2efc=(this.optiResult['fan_speed_B2EFC'])/50;
  //           var b2efc=Math.pow(xb2efc,3);
  //           b2efc=(1-b2efc)*100;
  //           //console.log(b2efc);

  //           var xb2el1=(this.optiResult['fan_speed_B2EL1'])/50;
  //           var b2el1=Math.pow(xb2el1,3);
  //           b2el1=(1-b2el1)*100;
  //           //console.log(b2el1);

  //           var xb2el2=(this.optiResult['fan_speed_B2EL2'])/50;
  //           var b2el2=Math.pow(xb2el2,3);
  //           b2el2=(1-b2el2)*100;
  //           //console.log(b2el2);

  //           var xb2el3=(this.optiResult['fan_speed_B2EL3'])/50;
  //           var b2el3=Math.pow(xb2el3,3);
  //           b2el3=(1-b2el3)*100;
  //           //console.log(b2el3);

  //           var xb2el4=(this.optiResult['fan_speed_B2EL4'])/50;
  //           var b2el4=Math.pow(xb2el4,3);
  //           b2el4=(1-b2el4)*100;
  //           //console.log(b2el4);

  //           this.KPI=(b2el4+b2el3+b2el2+b2el1+b2efc)/5;
  //           this.KPI=(this.KPI)/(100);

  //         } catch (error) {
  //           console.error('Error processing prediction result:', error);
  //         }
  //       },
  //       (error) => {
  //         console.error('Error fetching optimized result:', error);
  //         this.openSnackBar('error in fetching predicted values')
  //       }
  //     );
  // }

  async getPredictionResult() {
    this.isloading = true;

    this.http
      .get<any>(`${environment.apiBaseUrl}/auth/get_result`)

      .subscribe(
        (response: any) => {
          try {
            this.isloading = false;

            this.optiResult = response.environment; // Directly assign the nested object

            var totpow =
              this.optiResult['Power_B2EFC'] +
              this.optiResult['Power_B2EL1'] +
              this.optiResult['Power_B2EL2'] +
              this.optiResult['Power_B2EL3'] +
              this.optiResult['Power_B2EL4'];
            totpow = totpow / 5;
            this.KPI = ((this.basepower - totpow) / this.basepower) * 100;
          } catch (error) {
            console.error('Error processing prediction result:', error);
          }
        },

        (error) => {
          this.isloading = false;

          console.error('Error fetching optimized result:', error);
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
            this.openSnackBar('Session expired');
          }
          this.openSnackBar('error in fetching predicted values');
        }
      );
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }
}
